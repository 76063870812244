{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2024-04-15",
    "auth": [
      "aws.auth#sigv4"
    ],
    "endpointPrefix": "application-signals",
    "protocol": "rest-json",
    "protocols": [
      "rest-json"
    ],
    "serviceFullName": "Amazon CloudWatch Application Signals",
    "serviceId": "Application Signals",
    "signatureVersion": "v4",
    "signingName": "application-signals",
    "uid": "application-signals-2024-04-15"
  },
  "operations": {
    "BatchGetServiceLevelObjectiveBudgetReport": {
      "http": {
        "requestUri": "/budget-report",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Timestamp",
          "SloIds"
        ],
        "members": {
          "Timestamp": {
            "type": "timestamp"
          },
          "SloIds": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Timestamp",
          "Reports",
          "Errors"
        ],
        "members": {
          "Timestamp": {
            "type": "timestamp"
          },
          "Reports": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Arn",
                "Name",
                "BudgetStatus"
              ],
              "members": {
                "Arn": {},
                "Name": {},
                "BudgetStatus": {},
                "Attainment": {
                  "type": "double"
                },
                "TotalBudgetSeconds": {
                  "type": "integer"
                },
                "BudgetSecondsRemaining": {
                  "type": "integer"
                },
                "Sli": {
                  "shape": "Se"
                },
                "Goal": {
                  "shape": "S15"
                }
              }
            }
          },
          "Errors": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Name",
                "Arn",
                "ErrorCode",
                "ErrorMessage"
              ],
              "members": {
                "Name": {},
                "Arn": {},
                "ErrorCode": {},
                "ErrorMessage": {}
              }
            }
          }
        }
      }
    },
    "CreateServiceLevelObjective": {
      "http": {
        "requestUri": "/slo",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "SliConfig"
        ],
        "members": {
          "Name": {},
          "Description": {},
          "SliConfig": {
            "shape": "S1k"
          },
          "Goal": {
            "shape": "S15"
          },
          "Tags": {
            "shape": "S1o"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Slo"
        ],
        "members": {
          "Slo": {
            "shape": "S1t"
          }
        }
      }
    },
    "DeleteServiceLevelObjective": {
      "http": {
        "method": "DELETE",
        "requestUri": "/slo/{Id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "GetService": {
      "http": {
        "requestUri": "/service",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "StartTime",
          "EndTime",
          "KeyAttributes"
        ],
        "members": {
          "StartTime": {
            "location": "querystring",
            "locationName": "StartTime",
            "type": "timestamp"
          },
          "EndTime": {
            "location": "querystring",
            "locationName": "EndTime",
            "type": "timestamp"
          },
          "KeyAttributes": {
            "shape": "Sg"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Service",
          "StartTime",
          "EndTime"
        ],
        "members": {
          "Service": {
            "type": "structure",
            "required": [
              "KeyAttributes",
              "MetricReferences"
            ],
            "members": {
              "KeyAttributes": {
                "shape": "Sg"
              },
              "AttributeMaps": {
                "shape": "S20"
              },
              "MetricReferences": {
                "shape": "S22"
              },
              "LogGroupReferences": {
                "shape": "S25"
              }
            }
          },
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "LogGroupReferences": {
            "shape": "S25"
          }
        }
      }
    },
    "GetServiceLevelObjective": {
      "http": {
        "method": "GET",
        "requestUri": "/slo/{Id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Slo"
        ],
        "members": {
          "Slo": {
            "shape": "S1t"
          }
        }
      }
    },
    "ListServiceDependencies": {
      "http": {
        "requestUri": "/service-dependencies",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "StartTime",
          "EndTime",
          "KeyAttributes"
        ],
        "members": {
          "StartTime": {
            "location": "querystring",
            "locationName": "StartTime",
            "type": "timestamp"
          },
          "EndTime": {
            "location": "querystring",
            "locationName": "EndTime",
            "type": "timestamp"
          },
          "KeyAttributes": {
            "shape": "Sg"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "MaxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "StartTime",
          "EndTime",
          "ServiceDependencies"
        ],
        "members": {
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "ServiceDependencies": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "OperationName",
                "DependencyKeyAttributes",
                "DependencyOperationName",
                "MetricReferences"
              ],
              "members": {
                "OperationName": {},
                "DependencyKeyAttributes": {
                  "shape": "Sg"
                },
                "DependencyOperationName": {},
                "MetricReferences": {
                  "shape": "S22"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListServiceDependents": {
      "http": {
        "requestUri": "/service-dependents",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "StartTime",
          "EndTime",
          "KeyAttributes"
        ],
        "members": {
          "StartTime": {
            "location": "querystring",
            "locationName": "StartTime",
            "type": "timestamp"
          },
          "EndTime": {
            "location": "querystring",
            "locationName": "EndTime",
            "type": "timestamp"
          },
          "KeyAttributes": {
            "shape": "Sg"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "MaxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "StartTime",
          "EndTime",
          "ServiceDependents"
        ],
        "members": {
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "ServiceDependents": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "DependentKeyAttributes",
                "MetricReferences"
              ],
              "members": {
                "OperationName": {},
                "DependentKeyAttributes": {
                  "shape": "Sg"
                },
                "DependentOperationName": {},
                "MetricReferences": {
                  "shape": "S22"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListServiceLevelObjectives": {
      "http": {
        "requestUri": "/slos",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "KeyAttributes": {
            "shape": "Sg"
          },
          "OperationName": {
            "location": "querystring",
            "locationName": "OperationName"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "MaxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SloSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Arn",
                "Name"
              ],
              "members": {
                "Arn": {},
                "Name": {},
                "KeyAttributes": {
                  "shape": "Sg"
                },
                "OperationName": {},
                "CreatedTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListServiceOperations": {
      "http": {
        "requestUri": "/service-operations",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "StartTime",
          "EndTime",
          "KeyAttributes"
        ],
        "members": {
          "StartTime": {
            "location": "querystring",
            "locationName": "StartTime",
            "type": "timestamp"
          },
          "EndTime": {
            "location": "querystring",
            "locationName": "EndTime",
            "type": "timestamp"
          },
          "KeyAttributes": {
            "shape": "Sg"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "MaxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "StartTime",
          "EndTime",
          "ServiceOperations"
        ],
        "members": {
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "ServiceOperations": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Name",
                "MetricReferences"
              ],
              "members": {
                "Name": {},
                "MetricReferences": {
                  "shape": "S22"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListServices": {
      "http": {
        "method": "GET",
        "requestUri": "/services",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "StartTime",
          "EndTime"
        ],
        "members": {
          "StartTime": {
            "location": "querystring",
            "locationName": "StartTime",
            "type": "timestamp"
          },
          "EndTime": {
            "location": "querystring",
            "locationName": "EndTime",
            "type": "timestamp"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "MaxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "StartTime",
          "EndTime",
          "ServiceSummaries"
        ],
        "members": {
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "ServiceSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "KeyAttributes",
                "MetricReferences"
              ],
              "members": {
                "KeyAttributes": {
                  "shape": "Sg"
                },
                "AttributeMaps": {
                  "shape": "S20"
                },
                "MetricReferences": {
                  "shape": "S22"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {
            "location": "querystring",
            "locationName": "ResourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "S1o"
          }
        }
      }
    },
    "StartDiscovery": {
      "http": {
        "requestUri": "/start-discovery",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/tag-resource",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "Tags"
        ],
        "members": {
          "ResourceArn": {},
          "Tags": {
            "shape": "S1o"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "http": {
        "requestUri": "/untag-resource",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "TagKeys"
        ],
        "members": {
          "ResourceArn": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateServiceLevelObjective": {
      "http": {
        "method": "PATCH",
        "requestUri": "/slo/{Id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "Id"
          },
          "Description": {},
          "SliConfig": {
            "shape": "S1k"
          },
          "Goal": {
            "shape": "S15"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Slo"
        ],
        "members": {
          "Slo": {
            "shape": "S1t"
          }
        }
      }
    }
  },
  "shapes": {
    "Se": {
      "type": "structure",
      "required": [
        "SliMetric",
        "MetricThreshold",
        "ComparisonOperator"
      ],
      "members": {
        "SliMetric": {
          "type": "structure",
          "required": [
            "MetricDataQueries"
          ],
          "members": {
            "KeyAttributes": {
              "shape": "Sg"
            },
            "OperationName": {},
            "MetricType": {},
            "MetricDataQueries": {
              "shape": "Sl"
            }
          }
        },
        "MetricThreshold": {
          "type": "double"
        },
        "ComparisonOperator": {}
      }
    },
    "Sg": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sl": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {},
          "MetricStat": {
            "type": "structure",
            "required": [
              "Metric",
              "Period",
              "Stat"
            ],
            "members": {
              "Metric": {
                "type": "structure",
                "members": {
                  "Namespace": {},
                  "MetricName": {},
                  "Dimensions": {
                    "shape": "Ss"
                  }
                }
              },
              "Period": {
                "type": "integer"
              },
              "Stat": {},
              "Unit": {}
            }
          },
          "Expression": {},
          "Label": {},
          "ReturnData": {
            "type": "boolean"
          },
          "Period": {
            "type": "integer"
          },
          "AccountId": {}
        }
      }
    },
    "Ss": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Name",
          "Value"
        ],
        "members": {
          "Name": {},
          "Value": {}
        }
      }
    },
    "S15": {
      "type": "structure",
      "members": {
        "Interval": {
          "type": "structure",
          "members": {
            "RollingInterval": {
              "type": "structure",
              "required": [
                "DurationUnit",
                "Duration"
              ],
              "members": {
                "DurationUnit": {},
                "Duration": {
                  "type": "integer"
                }
              }
            },
            "CalendarInterval": {
              "type": "structure",
              "required": [
                "StartTime",
                "DurationUnit",
                "Duration"
              ],
              "members": {
                "StartTime": {
                  "type": "timestamp"
                },
                "DurationUnit": {},
                "Duration": {
                  "type": "integer"
                }
              }
            }
          },
          "union": true
        },
        "AttainmentGoal": {
          "type": "double"
        },
        "WarningThreshold": {
          "type": "double"
        }
      }
    },
    "S1k": {
      "type": "structure",
      "required": [
        "SliMetricConfig",
        "MetricThreshold",
        "ComparisonOperator"
      ],
      "members": {
        "SliMetricConfig": {
          "type": "structure",
          "members": {
            "KeyAttributes": {
              "shape": "Sg"
            },
            "OperationName": {},
            "MetricType": {},
            "Statistic": {},
            "PeriodSeconds": {
              "type": "integer"
            },
            "MetricDataQueries": {
              "shape": "Sl"
            }
          }
        },
        "MetricThreshold": {
          "type": "double"
        },
        "ComparisonOperator": {}
      }
    },
    "S1o": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key",
          "Value"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "S1t": {
      "type": "structure",
      "required": [
        "Arn",
        "Name",
        "CreatedTime",
        "LastUpdatedTime",
        "Sli",
        "Goal"
      ],
      "members": {
        "Arn": {},
        "Name": {},
        "Description": {},
        "CreatedTime": {
          "type": "timestamp"
        },
        "LastUpdatedTime": {
          "type": "timestamp"
        },
        "Sli": {
          "shape": "Se"
        },
        "Goal": {
          "shape": "S15"
        }
      }
    },
    "S20": {
      "type": "list",
      "member": {
        "type": "map",
        "key": {},
        "value": {}
      }
    },
    "S22": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Namespace",
          "MetricType",
          "MetricName"
        ],
        "members": {
          "Namespace": {},
          "MetricType": {},
          "Dimensions": {
            "shape": "Ss"
          },
          "MetricName": {}
        }
      }
    },
    "S25": {
      "type": "list",
      "member": {
        "shape": "Sg"
      }
    }
  }
}