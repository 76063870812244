{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2018-05-10",
    "auth": [
      "aws.auth#sigv4"
    ],
    "endpointPrefix": "artifact",
    "protocol": "rest-json",
    "protocols": [
      "rest-json"
    ],
    "serviceFullName": "AWS Artifact",
    "serviceId": "Artifact",
    "signatureVersion": "v4",
    "signingName": "artifact",
    "uid": "artifact-2018-05-10"
  },
  "operations": {
    "GetAccountSettings": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/account-settings/get",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "accountSettings": {
            "shape": "S3"
          }
        }
      }
    },
    "GetReport": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/report/get",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "reportId",
          "termToken"
        ],
        "members": {
          "reportId": {
            "location": "querystring",
            "locationName": "reportId"
          },
          "reportVersion": {
            "location": "querystring",
            "locationName": "reportVersion",
            "type": "long"
          },
          "termToken": {
            "location": "querystring",
            "locationName": "termToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "documentPresignedUrl": {}
        }
      }
    },
    "GetReportMetadata": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/report/getMetadata",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "reportId"
        ],
        "members": {
          "reportId": {
            "location": "querystring",
            "locationName": "reportId"
          },
          "reportVersion": {
            "location": "querystring",
            "locationName": "reportVersion",
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "reportDetails": {
            "type": "structure",
            "members": {
              "id": {},
              "name": {},
              "description": {},
              "periodStart": {
                "shape": "Sf"
              },
              "periodEnd": {
                "shape": "Sf"
              },
              "createdAt": {
                "shape": "Sf"
              },
              "lastModifiedAt": {
                "shape": "Sf"
              },
              "deletedAt": {
                "shape": "Sf"
              },
              "state": {},
              "arn": {},
              "series": {},
              "category": {},
              "companyName": {},
              "productName": {},
              "termArn": {},
              "version": {
                "type": "long"
              },
              "acceptanceType": {},
              "sequenceNumber": {
                "type": "long"
              },
              "uploadState": {},
              "statusMessage": {}
            }
          }
        }
      }
    },
    "GetTermForReport": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/report/getTermForReport",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "reportId"
        ],
        "members": {
          "reportId": {
            "location": "querystring",
            "locationName": "reportId"
          },
          "reportVersion": {
            "location": "querystring",
            "locationName": "reportVersion",
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "documentPresignedUrl": {},
          "termToken": {}
        }
      }
    },
    "ListReports": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/report/list",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "reports": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "id": {},
                "name": {},
                "state": {},
                "arn": {},
                "version": {
                  "type": "long"
                },
                "uploadState": {},
                "description": {},
                "periodStart": {
                  "shape": "Sf"
                },
                "periodEnd": {
                  "shape": "Sf"
                },
                "series": {},
                "category": {},
                "companyName": {},
                "productName": {},
                "statusMessage": {},
                "acceptanceType": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "PutAccountSettings": {
      "http": {
        "method": "PUT",
        "requestUri": "/v1/account-settings/put",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "notificationSubscriptionStatus": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "accountSettings": {
            "shape": "S3"
          }
        }
      },
      "idempotent": true
    }
  },
  "shapes": {
    "S3": {
      "type": "structure",
      "members": {
        "notificationSubscriptionStatus": {}
      }
    },
    "Sf": {
      "type": "timestamp",
      "timestampFormat": "iso8601"
    }
  }
}