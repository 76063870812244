{
  "pagination": {
    "DescribeAccountAttributes": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "AccountAttributes"
    },
    "DescribeAccountLimits": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "AccountLimits"
    },
    "DescribeConfigurationSets": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "ConfigurationSets"
    },
    "DescribeKeywords": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "Keywords"
    },
    "DescribeOptOutLists": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "OptOutLists"
    },
    "DescribeOptedOutNumbers": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "OptedOutNumbers"
    },
    "DescribePhoneNumbers": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "PhoneNumbers"
    },
    "DescribePools": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "Pools"
    },
    "DescribeProtectConfigurations": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "ProtectConfigurations"
    },
    "DescribeRegistrationAttachments": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "RegistrationAttachments"
    },
    "DescribeRegistrationFieldDefinitions": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "RegistrationFieldDefinitions"
    },
    "DescribeRegistrationFieldValues": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "RegistrationFieldValues"
    },
    "DescribeRegistrationSectionDefinitions": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "RegistrationSectionDefinitions"
    },
    "DescribeRegistrationTypeDefinitions": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "RegistrationTypeDefinitions"
    },
    "DescribeRegistrationVersions": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "RegistrationVersions"
    },
    "DescribeRegistrations": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "Registrations"
    },
    "DescribeSenderIds": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "SenderIds"
    },
    "DescribeSpendLimits": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "SpendLimits"
    },
    "DescribeVerifiedDestinationNumbers": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "VerifiedDestinationNumbers"
    },
    "ListPoolOriginationIdentities": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "OriginationIdentities"
    },
    "ListRegistrationAssociations": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "RegistrationAssociations"
    }
  }
}
