import {React, useState, useEffect} from "react";
import "./Jepx.css";
import { useEffect } from "react";
import Jepx_chart from "./JepxChart";
import { Grid } from '@mantine/core';
import { Table } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import AWS from 'aws-sdk';
import 'dayjs/locale/ja';
import dayjs from "dayjs";

// AWS呼ぶ
AWS.config.update({
  region: 'ap-northeast-1',
  credentials: {
    accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID, 
    secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESSKEY,
  },
});

const s3 = new AWS.S3();
let keisan_data = {}

function Jepx_price (props){
  // const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(null);
  const [s3_data, sets3Data] = useState('');

  const timezoneOffset = (new Date()).getTimezoneOffset();

  // setSelectedDate、日付を選んだ場合
  const handleChange = (e) => {
    if (e == null) {
      setSelectedDate(new Date())
    }
    else{
      setSelectedDate(e)
    }

  };

  // 11時前は今日のデータ、11時後は明日のデータ設定
  function getDefaultDate() {
    const today = new Date();
    if(timezoneOffset==0){
      const fiveAM = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 2, 0, 0);
      if (today.getTime() >= fiveAM.getTime()) {
      return new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
    } 
    else {
      return today;
    }
    }else{
    const elevenAM = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 11, 0, 0);
      if (today.getTime() >= elevenAM.getTime()) {
      return new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
    } 
    else {
      return today;
    }
    }
    
  }
  
  useEffect(() => {
    let formattedDate;
    if(selectedDate==null){
      // Format the selected date as YYYYMMDD
      formattedDate = `${getDefaultDate().getFullYear()}${(getDefaultDate().getMonth() + 1)
        .toString()
        .padStart(2, '0')}${getDefaultDate().getDate().toString().padStart(2, '0')}`;

      // console.log("Null So formattedDate",formattedDate)
    }
    else{
      formattedDate = `${selectedDate.getFullYear()}${(selectedDate.getMonth() + 1)
        .toString()
        .padStart(2, '0')}${selectedDate.getDate().toString().padStart(2, '0')}`;

      // console.log("Not Null So formattedDate",formattedDate)
    }

    // S3Buckerを呼ぶ
    async function getObject() {
      const params = {
        Bucket: process.env.REACT_APP_S3_BUCKET,
        Key: `${process.env.REACT_APP_S3_KEY}${formattedDate.substring(0, 4)}/${formattedDate.substring(4, 6)}/${formattedDate}.csv`,
      };

      // エラーを避けるため
      try {
        const s3_data = await s3.getObject(params).promise()
        // S3のデータが日付時刻を確認
        if(s3_data.Body.toString('utf-8').includes('日付時刻')) {
          sets3Data(s3_data.Body.toString('utf-8'))
        } else {
          sets3Data('')
          keisan_data = {}
        }
      }
      catch(err) {
        const params = {
          Bucket: process.env.REACT_APP_S3_BUCKET,
          Key: `${process.env.REACT_APP_S3_KEY}${formattedDate.substring(0, 4)}/${formattedDate.substring(4, 6)}/${formattedDate.slice(0, -1) + (parseInt(formattedDate.slice(-1)) - 1)}.csv`,
        };
        const s3_data = await s3.getObject(params).promise()
        // S3のデータが日付時刻を確認
        if(s3_data.Body.toString('utf-8').includes('日付時刻')) {
          sets3Data(s3_data.Body.toString('utf-8'))
        } else {
          sets3Data('')
          keisan_data = {}
        }
      }
    }
    getObject();
  
  }, [selectedDate]);

  
  
  // S3データを処理する、Jsonにする
  const rows = s3_data.trim().split('\n');
  // The first row contains the column headers, so we can extract them
  const headers = rows.shift().split(',');
  // console.log(headers)

  // Now we can create an object with arrays for each column
  let data_s3 = headers.reduce((obj, header) => {
    obj[header] = []
    return obj;
  }, {});

  // console.log(data_s3)

  // Populate the arrays with data from each row
  rows.forEach(row => {
    const values = row.split(',');
    headers.forEach((header, index) => {
      if(header=="日付時刻"){
      data_s3[header].push(values[index].split(' ')[1].substr(0, 5));
    }else{
      data_s3[header].push(parseFloat(values[index]));
    }
    });
  });
    
  if(s3_data!=''){
    const time_period = ["00:00","06:00","10:00","14:00","18:00","23:30"]
    for(let x of Object.getOwnPropertyNames(data_s3).slice(1)){
      const cut_data = {}
      for(let i=0; i<time_period.length-1; i++){
        const startTimeindex = data_s3["日付時刻"].indexOf(time_period[i])
        const endTimeindex = data_s3["日付時刻"].indexOf(time_period[i+1])
      
        const Time = data_s3["日付時刻"].slice(startTimeindex,endTimeindex+1)
        let dataOfTime;
        if(time_period[i+1]=="23:30"){
          dataOfTime = data_s3[x].slice(startTimeindex,endTimeindex+1)
        }
        else{
          dataOfTime = data_s3[x].slice(startTimeindex,endTimeindex)
        }
        const Avg = parseFloat(((dataOfTime.reduce((acc,val)=>acc+val,0))/dataOfTime.length)).toFixed(2)
      
        const max_value = Math.max(...dataOfTime)
        let TimePeriod_max;
        if(Time[dataOfTime.indexOf(max_value)]=="23:30"){
          TimePeriod_max = `${Time[dataOfTime.indexOf(max_value)]}~00:00`
        }
        else{
          TimePeriod_max = `${Time[dataOfTime.indexOf(max_value)]}~${Time[(dataOfTime.indexOf(max_value))+1]}`
        }
        
        const Max = {
          "Value":max_value.toFixed(2),
          "TimePeriod":TimePeriod_max,
          "All_Max":Math.max(...data_s3[x]),
        }
        
        const min_value = Math.min(...dataOfTime)
        let TimePeriod_min;
        if(Time[dataOfTime.indexOf(min_value)]=="23:30"){
          TimePeriod_min = `${Time[dataOfTime.indexOf(min_value)]}~00:00`
        }
        else{
          TimePeriod_min = `${Time[dataOfTime.indexOf(min_value)]}~${Time[(dataOfTime.indexOf(min_value))+1]}`
        }
        const Min = {
          "Value":min_value.toFixed(2),
          "TimePeriod":TimePeriod_min,
          "All_Min":Math.min(...data_s3[x]),
        }
      
        cut_data[`${time_period[i]}~${time_period[i+1]}`] = {
          "Time":Time,
          "Data":dataOfTime,
          "Avg":Avg,
          "Max":Max,
          "Min":Min,
        }
        
      }
      keisan_data[x] = cut_data
    }
  }else{
    keisan_data={}
  }

  // console.log("keisan_data",keisan_data)


  return (
   <div className="full_page">
    <div className="page1">
    <div className="box_header">
      <div className="date">
      <DatePicker
        size={20}
        styles={{
          root: { border: '2px solid white', color:'red' },
          input: { color: '#4572c4', fontWeight: 600 },
        }}
        locale='ja'
        value={
          selectedDate==null?(
            getDefaultDate() 
          ):
          selectedDate
        }
        onChange={(e)=>handleChange(e)}
        placeholder="YYYY年MM月DD日dd"
        inputFormat="YYYY年MM月DD日(dd)"
        minDate={dayjs(new Date(2023,2,28)).toDate()}
        maxDate={getDefaultDate()}
      />
      </div>
      <div className="area_text">
        各エリアの市場価格: 
      </div>
      <div className="line">
        <img className="image_kuma" src={"/img/jepx/powerkun.svg"}alt="Group" />
        <img className="image_logo" src={"/img/shirokuma.png"}alt="Group" />
      </div>
    </div>


    <div className="Graph">
      <Grid grow>
        <Grid.Col span={4} style={{ height: 450 }}>
          <Jepx_chart use_data={data_s3["北海道の価格"]} name={"北海道エリア"} color={"#9cb0da"}/>
        </Grid.Col>

        <Grid.Col span={4} >
        {/* <h3 style={{ textAlign: "center" }}>お客様の需要電力</h3> */}
          <Jepx_chart use_data={data_s3["東北の価格"]} name={"東北エリア"} color={"#88ced3"}/>
        </Grid.Col>

        <Grid.Col span={4} >
          <Jepx_chart use_data={data_s3["東京の価格"]} name={"東京エリア"} color={"#f5b4a3"}/>
        </Grid.Col>

        <Grid.Col span={4} style={{ height: 450 }}>
          <Jepx_chart use_data={data_s3["中部の価格"]} name={"中部エリア"} color={"#fee565"}/>
        </Grid.Col>

        <Grid.Col span={4}>
          <Jepx_chart use_data={data_s3["北陸の価格"]} name={"北陸エリア"} color={"#87c2eb"}/>
        </Grid.Col>

        <Grid.Col span={4}>
          <Jepx_chart use_data={data_s3["関西の価格"]} name={"関西エリア"} color={"#c3a679"}/>
        </Grid.Col>

        <Grid.Col span={4} style={{ height: 450 }}>
          <Jepx_chart use_data={data_s3["中国の価格"]} name={"中国エリア"} color={"#c0a4c1"}/>
        </Grid.Col>

        <Grid.Col span={4}>
          <Jepx_chart use_data={data_s3["四国の価格"]} name={"四国エリア"} color={"#f6b262"}/>
        </Grid.Col>

        <Grid.Col span={4}>
          <Jepx_chart use_data={data_s3["九州の価格"]} name={"九州エリア"} color={"#6badde"}/>
        </Grid.Col>

      </Grid>
    </div>
  </div>

  <div className="page2">
    <div className="box_header_2">
      <div className="date">
      <DatePicker
        size={20}
        styles={{
          root: { border: '2px solid white', color:'red' },
          input: { color: '#4572c4', fontWeight: 600 },
        }}
        locale='ja'
        value={
          selectedDate==null?(
            getDefaultDate() 
          ):
          selectedDate
        }
        onChange={(e)=>handleChange(e)}
        placeholder="YYYY年MM月DD日dd"
        inputFormat="YYYY年MM月DD日(dd)"
        minDate={dayjs(new Date(2023,2,28)).toDate()}
        maxDate={getDefaultDate()}
      />
      </div>
      <div className="area_text">
        各エリアの市場価格: 
        {/* 2023年3月29日(水)         */}
      </div>
      <div className="line">
      <img className="image_kuma" src={"/img/jepx/powerkun.svg"}alt="Group" />
      <img className="image_logo" src={"/img/shirokuma.png"}alt="Group" />
      </div>
    </div>
        <div className="table">
            <Grid grow >
            <div className="table_data">
              <Table horizontalSpacing={1} fontSize={11} withBorder withColumnBorders style={{ textAlign: 'center', width:1890, fontWeight:'bold'}}>
                <thead>
                  <tr>
                    <th style={{border: '1px solid white', borderRight: '1px solid black', borderBottom: '1px solid black'}}></th>
                    <th style={{ textAlign: 'center',border: '1px solid black' }}>00:00</th>
                    <th style={{ textAlign: 'center',border: '1px solid black' }}>00:30</th>
                    <th style={{ textAlign: 'center',border: '1px solid black' }}>01:00</th>
                    <th style={{ textAlign: 'center',border: '1px solid black' }}>01:30</th>
                    <th style={{ textAlign: 'center',border: '1px solid black' }}>02:00</th>
                    <th style={{ textAlign: 'center',border: '1px solid black' }}>02:30</th>
                    <th style={{ textAlign: 'center',border: '1px solid black' }}>03:00</th>
                    <th style={{ textAlign: 'center',border: '1px solid black' }}>03:30</th>
                    <th style={{ textAlign: 'center',border: '1px solid black' }}>04:00</th>
                    <th style={{ textAlign: 'center',border: '1px solid black' }}>04:30</th>
                    <th style={{ textAlign: 'center',border: '1px solid black' }}>05:00</th>
                    <th style={{ textAlign: 'center',border: '1px solid black' }}>05:30</th>
                    <th style={{ textAlign: 'center',border: '1px solid black' }}>06:00</th>
                    <th style={{ textAlign: 'center',border: '1px solid black' }}>06:30</th>
                    <th style={{ textAlign: 'center',border: '1px solid black' }}>07:00</th>
                    <th style={{ textAlign: 'center',border: '1px solid black' }}>07:30</th>
                    <th style={{ textAlign: 'center',border: '1px solid black' }}>08:00</th>
                    <th style={{ textAlign: 'center',border: '1px solid black' }}>08:30</th>
                    <th style={{ textAlign: 'center',border: '1px solid black' }}>09:00</th>
                    <th style={{ textAlign: 'center',border: '1px solid black' }}>09:30</th>
                    <th style={{ textAlign: 'center',border: '1px solid black' }}>10:00</th>
                    <th style={{ textAlign: 'center',border: '1px solid black' }}>10:30</th>
                    <th style={{ textAlign: 'center',border: '1px solid black' }}>11:00</th>
                    <th style={{ textAlign: 'center',border: '1px solid black' }}>11:30</th>
                    <th style={{ textAlign: 'center',border: '1px solid black' }}>12:00</th>
                    <th style={{ textAlign: 'center',border: '1px solid black' }}>12:30</th>
                    <th style={{ textAlign: 'center',border: '1px solid black' }}>13:00</th>
                    <th style={{ textAlign: 'center',border: '1px solid black' }}>13:30</th>
                    <th style={{ textAlign: 'center',border: '1px solid black' }}>14:00</th>
                    <th style={{ textAlign: 'center',border: '1px solid black' }}>14:30</th>
                    <th style={{ textAlign: 'center',border: '1px solid black' }}>15:00</th>
                    <th style={{ textAlign: 'center',border: '1px solid black' }}>15:30</th>
                    <th style={{ textAlign: 'center',border: '1px solid black' }}>16:00</th>
                    <th style={{ textAlign: 'center',border: '1px solid black' }}>16:30</th>
                    <th style={{ textAlign: 'center',border: '1px solid black' }}>17:00</th>
                    <th style={{ textAlign: 'center',border: '1px solid black' }}>17:30</th>
                    <th style={{ textAlign: 'center',border: '1px solid black' }}>18:00</th>
                    <th style={{ textAlign: 'center',border: '1px solid black' }}>18:30</th>
                    <th style={{ textAlign: 'center',border: '1px solid black' }}>19:00</th>
                    <th style={{ textAlign: 'center',border: '1px solid black' }}>19:30</th>
                    <th style={{ textAlign: 'center',border: '1px solid black' }}>20:00</th>
                    <th style={{ textAlign: 'center',border: '1px solid black' }}>20:30</th>
                    <th style={{ textAlign: 'center',border: '1px solid black' }}>21:00</th>
                    <th style={{ textAlign: 'center',border: '1px solid black' }}>21:30</th>
                    <th style={{ textAlign: 'center',border: '1px solid black' }}>22:00</th>
                    <th style={{ textAlign: 'center',border: '1px solid black' }}>22:30</th>
                    <th style={{ textAlign: 'center',border: '1px solid black' }}>23:00</th>
                    <th style={{ textAlign: 'center',border: '1px solid black' }}>23:30</th>
                  </tr>
                </thead>
                
                <tbody>
                  {
                    Object.getOwnPropertyNames(data_s3).slice(1).map((property)=>(
                      <tr key={property}>
                        <td key={property} style={{ minWidth: 70, fontSize:15, border:'1px solid black',fontWeight: '500'}}>{property.replace("の価格","")}</td>
                        {
                          data_s3[property].map((value,index)=>(
                            value<3?(
                              <td key={`${property}_${index}`} style={{ background:'#e1eeda',border: '1px solid black'}}>{value.toFixed(2)}</td>
                            ):
                            value>=3 && value<5?(
                            <td key={`${property}_${index}`} style={{ background:'#c6e1b4',border: '1px solid black' }}>{value.toFixed(2)}</td>
                            ):
                            value>=5 && value<10?(
                              <td key={`${property}_${index}`} style={{ background:'#bed6ee',border: '1px solid black' }}>{value.toFixed(2)}</td>
                              ):
                              value>=10 && value<15?(
                                <td key={`${property}_${index}`} style={{ background:'#24aaff',border: '1px solid black' }}>{value.toFixed(2)}</td>
                                ):
                                value>=15 && value<20?(
                                  <td key={`${property}_${index}`} style={{ background:'#0141ff',border: '1px solid black' }}>{value.toFixed(2)}</td>
                                  ):
                                  value>=20 && value<30?(
                                    <td key={`${property}_${index}`} style={{ background:'#fae695',border: '1px solid black' }}>{value.toFixed(2)}</td>
                                    ):
                                    value>=30 && value<40?(
                                      <td key={`${property}_${index}`} style={{ background:'#ffe700',border: '1px solid black' }}>{value.toFixed(2)}</td>
                                      ):
                                      value>=40 && value<50?(
                                        <td key={`${property}_${index}`} style={{ background:'#fb9900',border: '1px solid black' }}>{value.toFixed(2)}</td>
                                        ):
                                        value>=50 && value<80?(
                                          <td key={`${property}_${index}`} style={{ background:'#f82803',border: '1px solid black' }}>{value.toFixed(2)}</td>
                                          ):
                                          value>=80 && value<100?(
                                            <td key={`${property}_${index}`} style={{ background:'#a40121',border: '1px solid black' }}>{value.toFixed(2)}</td>
                                            ):
                                            value>=100?(
                                              <td key={`${property}_${index}`} style={{ background:'#b40867',border: '1px solid black' }}>{value.toFixed(2)}</td>
                                              ):
                            <td key={`${property}_${index}`} style={{ background:'white' }}>{value.toFixed(2)}</td>
                            )
                          )
                        }
                      </tr>
                      )
                    )
                  }
                </tbody> 
              </Table>
              </div>
              </Grid>

              
              <div className="hanrei">
                <Table horizontalSpacing={-1} fontSize={10} style={{ padding:'1px',border: '1px solid black', textAlign: 'center', width:600, height:10}}>
                <thead>
                  <tr style={{ padding: '1px' }}>
                  <th style={{ border: '1px solid white', borderRight: '1px solid black' ,textAlign: 'center', width:150, position: 'relative', top: '10px', fontSize:20 , color:'black'}}>【凡例】</th>
                    <th style={{ border: '1px solid black', background:'#e1eeda', textAlign: 'center', color:'black'}}>3円<br/>未満</th>
                    <th style={{ border: '1px solid black', background:'#c6e1b4', textAlign: 'center', color:'black' }}>5円<br/>未満</th>
                    <th style={{ border: '1px solid black', background:'#bed6ee', textAlign: 'center', color:'black' }}>10円<br/>未満</th>
                    <th style={{ border: '1px solid black', background:'#24aaff', textAlign: 'center', color:'black' }}>15円<br/>未満</th>
                    <th style={{ border: '1px solid black', background:'#0141ff', textAlign: 'center', color:'white' }}>20円<br/>未満</th>
                    <th style={{ border: '1px solid black', background:'#fae695', textAlign: 'center', color:'black' }}>30円<br/>未満</th>
                    <th style={{ border: '1px solid black', background:'#ffe700', textAlign: 'center', color:'black' }}>40円<br/>未満</th>
                    <th style={{ border: '1px solid black', background:'#fb9900', textAlign: 'center', color:'black' }}>50円<br/>未満</th>
                    <th style={{ border: '1px solid black', background:'#f82803', textAlign: 'center', color:'black' }}>80円<br/>未満</th>
                    <th style={{ border: '1px solid black', background:'#a40121', textAlign: 'center', color:'white' }}>100円<br/>未満</th>
                    <th style={{ border: '1px solid black', background:'#b40867', textAlign: 'center', color:'white' }}>100円<br/>以上</th>
                    {/* <th style={{ border: '1px solid black', background:'#e1eeda', textAlign: 'center'}}>3円未満</th>
                    <th style={{ border: '1px solid black', background:'#c6e1b4', textAlign: 'center' }}>5円未満</th>
                    <th style={{ border: '1px solid black', background:'#bed6ee', textAlign: 'center' }}>10円未満</th>
                    <th style={{ border: '1px solid black', background:'#24aaff', textAlign: 'center' }}>15円未満</th>
                    <th style={{ border: '1px solid black', background:'#0141ff', textAlign: 'center', color:'white' }}>20円未満</th>
                    <th style={{ border: '1px solid black', background:'#fae695', textAlign: 'center' }}>30円未満</th>
                    <th style={{ border: '1px solid black', background:'#ffe700', textAlign: 'center' }}>40円未満</th>
                    <th style={{ border: '1px solid black', background:'#fb9900', textAlign: 'center' }}>50円未満</th>
                    <th style={{ border: '1px solid black', background:'#f82803', textAlign: 'center' }}>80円未満</th>
                    <th style={{ border: '1px solid black', background:'#a40121', textAlign: 'center', color:'white' }}>100円未満</th>
                    <th style={{ border: '1px solid black', background:'#b40867', textAlign: 'center', color:'white' }}>100円以上</th> */}
                  </tr>
                </thead>
                </Table>
              </div>
              
        </div>

        <div className="keisan">
          <Grid grow >
            {
              Object.getOwnPropertyNames(keisan_data).map((key)=>(
                <Grid.Col span={4}>
                  <div key ={keisan_data} style={{lineHeight: '40px',height:40,border: '1px solid #808080', background:'#d9d9d9', textAlign: 'center', fontSize:25, color:'black'}}>{key.replace("の価格", "エリア")}</div>
              <Table fontSize={15}>
              <thead>
                <tr></tr>
              <tr >
                <th key ={`${keisan_data}_時間帯`} style={{ border: '1px solid #808080', background:'white', textAlign: 'center', color:'black'}}>時間帯</th>
                <th key ={`${keisan_data}_0:00~6:00`} style={{ border: '1px solid #808080', background:'white', textAlign: 'center', color:'black', fontSize:15}}>0:00~6:00</th>
                <th key ={`${keisan_data}_6:00~10:00`} style={{ border: '1px solid #808080', background:'white', textAlign: 'center', color:'black', fontSize:15}}>6:00~10:00</th>
                <th key ={`${keisan_data}_10:00~14:00`} style={{ border: '1px solid #808080', background:'white', textAlign: 'center', color:'black', fontSize:15}}>10:00~14:00</th>
                <th key ={`${keisan_data}_14:00~18:00`} style={{ border: '1px solid #808080', background:'white', textAlign: 'center', color:'black', fontSize:15}}>14:00~18:00</th>
                <th key ={`${keisan_data}_18:00~24:00`} style={{ border: '1px solid #808080', background:'white', textAlign: 'center', color:'black', fontSize:15}}>18:00~24:00</th>
              </tr>
            </thead>
            <tbody>
              {
                // Object.getOwnPropertyNames(data_s3).slice(1).map((property)=>())
                ["平均","最高値","最安値"].map((name)=>(
                  name=="平均"?
                  (
                  <tr key={`${keisan_data}_${name}`}>
                    <td key={name} style={{ border: '1px solid #808080', background:'white', textAlign: 'center', color:'black', fontWeight: 'bold'}}>{name}</td>
                    {
                      Object.getOwnPropertyNames(keisan_data[key]).map((property)=>(
                        <td key={property} style={{ border: '1px solid #808080', background:'white', textAlign: 'center', color:'black',fontWeight: 'bold', fontSize:21}}>{keisan_data[key][property]["Avg"]}</td>
                      ))
                    }
                  </tr>
                  ):
                  name=="最高値"?(
                  <tr key={name}>
                    <td  key={name} style={{ border: '1px solid #808080', background:'#fdd6d9', textAlign: 'center', color:'#bf0000',fontWeight: 'bold'}}>{name}<br/>(時間帯)</td>
                    {
                        Object.getOwnPropertyNames(keisan_data[key]).map((property)=>(
                          keisan_data[key][property]["Max"]["Value"]==keisan_data[key][property]["Max"]["All_Max"]?(
                          <td key={property} style={{ border: '1px solid #808080', background:'#f80103', textAlign: 'center', color:'white' }}>
                          <span style={{ fontWeight: 'bold', fontSize:21 }}>{keisan_data[key][property]["Max"]["Value"]}</span><br/>{keisan_data[key][property]["Max"]["TimePeriod"]}</td>
                          ):
                          <td key={property} style={{ border: '1px solid #808080', background:'#fdd6d9', textAlign: 'center', color:'#bf0000'}}>
                          <span style={{ fontWeight: 'bold', fontSize:21 }}>{keisan_data[key][property]["Max"]["Value"]}</span><br/>{keisan_data[key][property]["Max"]["TimePeriod"]}</td>
                      ))

                    }
                  </tr>
                  ):
                  <tr key={name}>
                    <td key={"最安値"} style={{ border: '1px solid #808080', background:'#d9e1f1', textAlign: 'center',color:'#1170c0',fontWeight: 'bold'}}>{name}<br/>(時間帯)</td>
                    {
                        Object.getOwnPropertyNames(keisan_data[key]).map((property)=>(
                          keisan_data[key][property]["Min"]["Value"]==keisan_data[key][property]["Min"]["All_Min"]?(
                          <td key={property}　style={{ border: '1px solid #808080', background:'#2f76b5', textAlign: 'center', color:'white'}}>
                          <span style={{ fontWeight: 'bold' , fontSize:21}}>{keisan_data[key][property]["Min"]["Value"]}</span><br/>{keisan_data[key][property]["Min"]["TimePeriod"]}</td>
                          ):
                          <td key={property} style={{ border: '1px solid #808080', background:'#d9e1f1', textAlign: 'center', color:'#1170c0'}}>
                          <span style={{ fontWeight: 'bold' , fontSize:21}}>{keisan_data[key][property]["Min"]["Value"]}</span><br/>{keisan_data[key][property]["Min"]["TimePeriod"]}</td>
                      ))

                    }
                  </tr>
                ))
              }
            </tbody>
              </Table>
            </Grid.Col>
              ))
            }
           
          </Grid>
        </div>
        <div style={{margin: '20px 0'}} className="Text">
            ・表の数字について:30分ごと×エリアにおける電力のスポット価格です。単位は円/kWhとなります。この価格に基づき、ご請求金額を決定いたします。
            <div style={{margin: '10px 0'}}>
            ・エリア(価格):エリア毎の取引価格になります。各大手電力管内を指しています(例:東京→東京電力)。日本では東日本での電気が50Hz、西日本では60Hzとなっております。他にも各エリアを跨ぐ際の容量に上限があるため、エリア別の価格が存在します
            </div>
            <div style={{margin: '10px 0'}}>
            ・時間:日本では30分毎に電気の取引価格が決まっており、表では各時間帯の始まりを示しています(例:14:00→14:00~14:30)
            </div>
            <div style={{margin: '10px 0'}}>
            ・データの出典:[日本卸電力取引所] http://www.jepx.org/index.html
            </div>
        </div>
    </div>
  </div> 
  );
}

export default Jepx_price;