{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2023-09-06",
    "auth": [
      "aws.auth#sigv4"
    ],
    "endpointPrefix": "cleanrooms-ml",
    "protocol": "rest-json",
    "protocols": [
      "rest-json"
    ],
    "serviceFullName": "AWS Clean Rooms ML",
    "serviceId": "CleanRoomsML",
    "signatureVersion": "v4",
    "signingName": "cleanrooms-ml",
    "uid": "cleanroomsml-2023-09-06"
  },
  "operations": {
    "CreateAudienceModel": {
      "http": {
        "requestUri": "/audience-model",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "name",
          "trainingDatasetArn"
        ],
        "members": {
          "trainingDataStartTime": {
            "shape": "S2"
          },
          "trainingDataEndTime": {
            "shape": "S2"
          },
          "name": {},
          "trainingDatasetArn": {},
          "kmsKeyArn": {},
          "tags": {
            "shape": "S6"
          },
          "description": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "audienceModelArn"
        ],
        "members": {
          "audienceModelArn": {}
        }
      },
      "idempotent": true
    },
    "CreateConfiguredAudienceModel": {
      "http": {
        "requestUri": "/configured-audience-model",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "name",
          "audienceModelArn",
          "outputConfig",
          "sharedAudienceMetrics"
        ],
        "members": {
          "name": {},
          "audienceModelArn": {},
          "outputConfig": {
            "shape": "Sd"
          },
          "description": {},
          "sharedAudienceMetrics": {
            "shape": "Si"
          },
          "minMatchingSeedSize": {
            "type": "integer"
          },
          "audienceSizeConfig": {
            "shape": "Sl"
          },
          "tags": {
            "shape": "S6"
          },
          "childResourceTagOnCreatePolicy": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "configuredAudienceModelArn"
        ],
        "members": {
          "configuredAudienceModelArn": {}
        }
      },
      "idempotent": true
    },
    "CreateTrainingDataset": {
      "http": {
        "requestUri": "/training-dataset",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "name",
          "roleArn",
          "trainingData"
        ],
        "members": {
          "name": {},
          "roleArn": {},
          "trainingData": {
            "type": "list",
            "member": {
              "shape": "Su"
            }
          },
          "tags": {
            "shape": "S6"
          },
          "description": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "trainingDatasetArn"
        ],
        "members": {
          "trainingDatasetArn": {}
        }
      },
      "idempotent": true
    },
    "DeleteAudienceGenerationJob": {
      "http": {
        "method": "DELETE",
        "requestUri": "/audience-generation-job/{audienceGenerationJobArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "audienceGenerationJobArn"
        ],
        "members": {
          "audienceGenerationJobArn": {
            "location": "uri",
            "locationName": "audienceGenerationJobArn"
          }
        }
      },
      "idempotent": true
    },
    "DeleteAudienceModel": {
      "http": {
        "method": "DELETE",
        "requestUri": "/audience-model/{audienceModelArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "audienceModelArn"
        ],
        "members": {
          "audienceModelArn": {
            "location": "uri",
            "locationName": "audienceModelArn"
          }
        }
      },
      "idempotent": true
    },
    "DeleteConfiguredAudienceModel": {
      "http": {
        "method": "DELETE",
        "requestUri": "/configured-audience-model/{configuredAudienceModelArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "configuredAudienceModelArn"
        ],
        "members": {
          "configuredAudienceModelArn": {
            "location": "uri",
            "locationName": "configuredAudienceModelArn"
          }
        }
      },
      "idempotent": true
    },
    "DeleteConfiguredAudienceModelPolicy": {
      "http": {
        "method": "DELETE",
        "requestUri": "/configured-audience-model/{configuredAudienceModelArn}/policy",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "configuredAudienceModelArn"
        ],
        "members": {
          "configuredAudienceModelArn": {
            "location": "uri",
            "locationName": "configuredAudienceModelArn"
          }
        }
      },
      "idempotent": true
    },
    "DeleteTrainingDataset": {
      "http": {
        "method": "DELETE",
        "requestUri": "/training-dataset/{trainingDatasetArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "trainingDatasetArn"
        ],
        "members": {
          "trainingDatasetArn": {
            "location": "uri",
            "locationName": "trainingDatasetArn"
          }
        }
      },
      "idempotent": true
    },
    "GetAudienceGenerationJob": {
      "http": {
        "method": "GET",
        "requestUri": "/audience-generation-job/{audienceGenerationJobArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "audienceGenerationJobArn"
        ],
        "members": {
          "audienceGenerationJobArn": {
            "location": "uri",
            "locationName": "audienceGenerationJobArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "createTime",
          "updateTime",
          "audienceGenerationJobArn",
          "name",
          "status",
          "configuredAudienceModelArn"
        ],
        "members": {
          "createTime": {
            "shape": "S2"
          },
          "updateTime": {
            "shape": "S2"
          },
          "audienceGenerationJobArn": {},
          "name": {},
          "description": {},
          "status": {},
          "statusDetails": {
            "shape": "S1h"
          },
          "configuredAudienceModelArn": {},
          "seedAudience": {
            "shape": "S1j"
          },
          "includeSeedInOutput": {
            "type": "boolean"
          },
          "collaborationId": {},
          "metrics": {
            "type": "structure",
            "required": [
              "relevanceMetrics"
            ],
            "members": {
              "relevanceMetrics": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "required": [
                    "audienceSize"
                  ],
                  "members": {
                    "audienceSize": {
                      "shape": "S1v"
                    },
                    "score": {
                      "type": "double"
                    }
                  }
                }
              },
              "recallMetric": {
                "type": "double"
              }
            }
          },
          "startedBy": {},
          "tags": {
            "shape": "S6"
          },
          "protectedQueryIdentifier": {}
        }
      }
    },
    "GetAudienceModel": {
      "http": {
        "method": "GET",
        "requestUri": "/audience-model/{audienceModelArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "audienceModelArn"
        ],
        "members": {
          "audienceModelArn": {
            "location": "uri",
            "locationName": "audienceModelArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "createTime",
          "updateTime",
          "audienceModelArn",
          "name",
          "trainingDatasetArn",
          "status"
        ],
        "members": {
          "createTime": {
            "shape": "S2"
          },
          "updateTime": {
            "shape": "S2"
          },
          "trainingDataStartTime": {
            "shape": "S2"
          },
          "trainingDataEndTime": {
            "shape": "S2"
          },
          "audienceModelArn": {},
          "name": {},
          "trainingDatasetArn": {},
          "status": {},
          "statusDetails": {
            "shape": "S1h"
          },
          "kmsKeyArn": {},
          "tags": {
            "shape": "S6"
          },
          "description": {}
        }
      }
    },
    "GetConfiguredAudienceModel": {
      "http": {
        "method": "GET",
        "requestUri": "/configured-audience-model/{configuredAudienceModelArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "configuredAudienceModelArn"
        ],
        "members": {
          "configuredAudienceModelArn": {
            "location": "uri",
            "locationName": "configuredAudienceModelArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "createTime",
          "updateTime",
          "configuredAudienceModelArn",
          "name",
          "audienceModelArn",
          "outputConfig",
          "status",
          "sharedAudienceMetrics"
        ],
        "members": {
          "createTime": {
            "shape": "S2"
          },
          "updateTime": {
            "shape": "S2"
          },
          "configuredAudienceModelArn": {},
          "name": {},
          "audienceModelArn": {},
          "outputConfig": {
            "shape": "Sd"
          },
          "description": {},
          "status": {},
          "sharedAudienceMetrics": {
            "shape": "Si"
          },
          "minMatchingSeedSize": {
            "type": "integer"
          },
          "audienceSizeConfig": {
            "shape": "Sl"
          },
          "tags": {
            "shape": "S6"
          },
          "childResourceTagOnCreatePolicy": {}
        }
      }
    },
    "GetConfiguredAudienceModelPolicy": {
      "http": {
        "method": "GET",
        "requestUri": "/configured-audience-model/{configuredAudienceModelArn}/policy",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "configuredAudienceModelArn"
        ],
        "members": {
          "configuredAudienceModelArn": {
            "location": "uri",
            "locationName": "configuredAudienceModelArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "configuredAudienceModelArn",
          "configuredAudienceModelPolicy",
          "policyHash"
        ],
        "members": {
          "configuredAudienceModelArn": {},
          "configuredAudienceModelPolicy": {},
          "policyHash": {}
        }
      }
    },
    "GetTrainingDataset": {
      "http": {
        "method": "GET",
        "requestUri": "/training-dataset/{trainingDatasetArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "trainingDatasetArn"
        ],
        "members": {
          "trainingDatasetArn": {
            "location": "uri",
            "locationName": "trainingDatasetArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "createTime",
          "updateTime",
          "trainingDatasetArn",
          "name",
          "trainingData",
          "status",
          "roleArn"
        ],
        "members": {
          "createTime": {
            "shape": "S2"
          },
          "updateTime": {
            "shape": "S2"
          },
          "trainingDatasetArn": {},
          "name": {},
          "trainingData": {
            "type": "list",
            "member": {
              "shape": "Su"
            }
          },
          "status": {},
          "roleArn": {},
          "tags": {
            "shape": "S6"
          },
          "description": {}
        }
      }
    },
    "ListAudienceExportJobs": {
      "http": {
        "method": "GET",
        "requestUri": "/audience-export-job",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "audienceGenerationJobArn": {
            "location": "querystring",
            "locationName": "audienceGenerationJobArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "audienceExportJobs"
        ],
        "members": {
          "nextToken": {},
          "audienceExportJobs": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "createTime",
                "updateTime",
                "name",
                "audienceGenerationJobArn",
                "audienceSize",
                "status"
              ],
              "members": {
                "createTime": {
                  "shape": "S2"
                },
                "updateTime": {
                  "shape": "S2"
                },
                "name": {},
                "audienceGenerationJobArn": {},
                "audienceSize": {
                  "shape": "S1v"
                },
                "description": {},
                "status": {},
                "statusDetails": {
                  "shape": "S1h"
                },
                "outputLocation": {}
              }
            }
          }
        }
      }
    },
    "ListAudienceGenerationJobs": {
      "http": {
        "method": "GET",
        "requestUri": "/audience-generation-job",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "configuredAudienceModelArn": {
            "location": "querystring",
            "locationName": "configuredAudienceModelArn"
          },
          "collaborationId": {
            "location": "querystring",
            "locationName": "collaborationId"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "audienceGenerationJobs"
        ],
        "members": {
          "nextToken": {},
          "audienceGenerationJobs": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "createTime",
                "updateTime",
                "audienceGenerationJobArn",
                "name",
                "status",
                "configuredAudienceModelArn"
              ],
              "members": {
                "createTime": {
                  "shape": "S2"
                },
                "updateTime": {
                  "shape": "S2"
                },
                "audienceGenerationJobArn": {},
                "name": {},
                "description": {},
                "status": {},
                "configuredAudienceModelArn": {},
                "collaborationId": {},
                "startedBy": {}
              }
            }
          }
        }
      }
    },
    "ListAudienceModels": {
      "http": {
        "method": "GET",
        "requestUri": "/audience-model",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "audienceModels"
        ],
        "members": {
          "nextToken": {},
          "audienceModels": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "createTime",
                "updateTime",
                "audienceModelArn",
                "name",
                "trainingDatasetArn",
                "status"
              ],
              "members": {
                "createTime": {
                  "shape": "S2"
                },
                "updateTime": {
                  "shape": "S2"
                },
                "audienceModelArn": {},
                "name": {},
                "trainingDatasetArn": {},
                "status": {},
                "description": {}
              }
            }
          }
        }
      }
    },
    "ListConfiguredAudienceModels": {
      "http": {
        "method": "GET",
        "requestUri": "/configured-audience-model",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "configuredAudienceModels"
        ],
        "members": {
          "nextToken": {},
          "configuredAudienceModels": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "createTime",
                "updateTime",
                "name",
                "audienceModelArn",
                "outputConfig",
                "configuredAudienceModelArn",
                "status"
              ],
              "members": {
                "createTime": {
                  "shape": "S2"
                },
                "updateTime": {
                  "shape": "S2"
                },
                "name": {},
                "audienceModelArn": {},
                "outputConfig": {
                  "shape": "Sd"
                },
                "description": {},
                "configuredAudienceModelArn": {},
                "status": {}
              }
            }
          }
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "tags"
        ],
        "members": {
          "tags": {
            "shape": "S6"
          }
        }
      }
    },
    "ListTrainingDatasets": {
      "http": {
        "method": "GET",
        "requestUri": "/training-dataset",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "trainingDatasets"
        ],
        "members": {
          "nextToken": {},
          "trainingDatasets": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "createTime",
                "updateTime",
                "trainingDatasetArn",
                "name",
                "status"
              ],
              "members": {
                "createTime": {
                  "shape": "S2"
                },
                "updateTime": {
                  "shape": "S2"
                },
                "trainingDatasetArn": {},
                "name": {},
                "status": {},
                "description": {}
              }
            }
          }
        }
      }
    },
    "PutConfiguredAudienceModelPolicy": {
      "http": {
        "method": "PUT",
        "requestUri": "/configured-audience-model/{configuredAudienceModelArn}/policy",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "configuredAudienceModelArn",
          "configuredAudienceModelPolicy"
        ],
        "members": {
          "configuredAudienceModelArn": {
            "location": "uri",
            "locationName": "configuredAudienceModelArn"
          },
          "configuredAudienceModelPolicy": {},
          "previousPolicyHash": {},
          "policyExistenceCondition": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "configuredAudienceModelPolicy",
          "policyHash"
        ],
        "members": {
          "configuredAudienceModelPolicy": {},
          "policyHash": {}
        }
      },
      "idempotent": true
    },
    "StartAudienceExportJob": {
      "http": {
        "requestUri": "/audience-export-job",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "name",
          "audienceGenerationJobArn",
          "audienceSize"
        ],
        "members": {
          "name": {},
          "audienceGenerationJobArn": {},
          "audienceSize": {
            "shape": "S1v"
          },
          "description": {}
        }
      },
      "idempotent": true
    },
    "StartAudienceGenerationJob": {
      "http": {
        "requestUri": "/audience-generation-job",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "name",
          "configuredAudienceModelArn",
          "seedAudience"
        ],
        "members": {
          "name": {},
          "configuredAudienceModelArn": {},
          "seedAudience": {
            "shape": "S1j"
          },
          "includeSeedInOutput": {
            "type": "boolean"
          },
          "collaborationId": {},
          "description": {},
          "tags": {
            "shape": "S6"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "audienceGenerationJobArn"
        ],
        "members": {
          "audienceGenerationJobArn": {}
        }
      },
      "idempotent": true
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tags"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tags": {
            "shape": "S6"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tagKeys"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tagKeys": {
            "location": "querystring",
            "locationName": "tagKeys",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "UpdateConfiguredAudienceModel": {
      "http": {
        "method": "PATCH",
        "requestUri": "/configured-audience-model/{configuredAudienceModelArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "configuredAudienceModelArn"
        ],
        "members": {
          "configuredAudienceModelArn": {
            "location": "uri",
            "locationName": "configuredAudienceModelArn"
          },
          "outputConfig": {
            "shape": "Sd"
          },
          "audienceModelArn": {},
          "sharedAudienceMetrics": {
            "shape": "Si"
          },
          "minMatchingSeedSize": {
            "type": "integer"
          },
          "audienceSizeConfig": {
            "shape": "Sl"
          },
          "description": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "configuredAudienceModelArn"
        ],
        "members": {
          "configuredAudienceModelArn": {}
        }
      },
      "idempotent": true
    }
  },
  "shapes": {
    "S2": {
      "type": "timestamp",
      "timestampFormat": "iso8601"
    },
    "S6": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sd": {
      "type": "structure",
      "required": [
        "destination",
        "roleArn"
      ],
      "members": {
        "destination": {
          "type": "structure",
          "required": [
            "s3Destination"
          ],
          "members": {
            "s3Destination": {
              "shape": "Sf"
            }
          }
        },
        "roleArn": {}
      }
    },
    "Sf": {
      "type": "structure",
      "required": [
        "s3Uri"
      ],
      "members": {
        "s3Uri": {}
      }
    },
    "Si": {
      "type": "list",
      "member": {}
    },
    "Sl": {
      "type": "structure",
      "required": [
        "audienceSizeType",
        "audienceSizeBins"
      ],
      "members": {
        "audienceSizeType": {},
        "audienceSizeBins": {
          "type": "list",
          "member": {
            "type": "integer"
          }
        }
      }
    },
    "Su": {
      "type": "structure",
      "required": [
        "type",
        "inputConfig"
      ],
      "members": {
        "type": {},
        "inputConfig": {
          "type": "structure",
          "required": [
            "schema",
            "dataSource"
          ],
          "members": {
            "schema": {
              "type": "list",
              "member": {
                "type": "structure",
                "required": [
                  "columnName",
                  "columnTypes"
                ],
                "members": {
                  "columnName": {},
                  "columnTypes": {
                    "type": "list",
                    "member": {}
                  }
                }
              }
            },
            "dataSource": {
              "type": "structure",
              "required": [
                "glueDataSource"
              ],
              "members": {
                "glueDataSource": {
                  "type": "structure",
                  "required": [
                    "tableName",
                    "databaseName"
                  ],
                  "members": {
                    "tableName": {},
                    "databaseName": {},
                    "catalogId": {}
                  }
                }
              }
            }
          }
        }
      }
    },
    "S1h": {
      "type": "structure",
      "members": {
        "statusCode": {},
        "message": {}
      }
    },
    "S1j": {
      "type": "structure",
      "required": [
        "roleArn"
      ],
      "members": {
        "dataSource": {
          "shape": "Sf"
        },
        "roleArn": {},
        "sqlParameters": {
          "type": "structure",
          "members": {
            "queryString": {},
            "analysisTemplateArn": {},
            "parameters": {
              "type": "map",
              "key": {},
              "value": {}
            }
          },
          "sensitive": true
        }
      }
    },
    "S1v": {
      "type": "structure",
      "required": [
        "type",
        "value"
      ],
      "members": {
        "type": {},
        "value": {
          "type": "integer"
        }
      }
    }
  }
}