{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2018-05-10",
    "endpointPrefix": "datazone",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceFullName": "Amazon DataZone",
    "serviceId": "DataZone",
    "signatureVersion": "v4",
    "signingName": "datazone",
    "uid": "datazone-2018-05-10"
  },
  "operations": {
    "AcceptPredictions": {
      "http": {
        "method": "PUT",
        "requestUri": "/v2/domains/{domainIdentifier}/assets/{identifier}/accept-predictions",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "identifier"
        ],
        "members": {
          "acceptChoices": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "predictionTarget"
              ],
              "members": {
                "editedValue": {
                  "type": "string",
                  "sensitive": true
                },
                "predictionChoice": {
                  "type": "integer"
                },
                "predictionTarget": {}
              }
            }
          },
          "acceptRule": {
            "type": "structure",
            "members": {
              "rule": {},
              "threshold": {
                "type": "float"
              }
            }
          },
          "clientToken": {
            "idempotencyToken": true
          },
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "identifier": {
            "location": "uri",
            "locationName": "identifier"
          },
          "revision": {
            "location": "querystring",
            "locationName": "revision"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "assetId",
          "domainId",
          "revision"
        ],
        "members": {
          "assetId": {},
          "domainId": {},
          "revision": {}
        }
      },
      "idempotent": true
    },
    "AcceptSubscriptionRequest": {
      "http": {
        "method": "PUT",
        "requestUri": "/v2/domains/{domainIdentifier}/subscription-requests/{identifier}/accept",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "identifier"
        ],
        "members": {
          "decisionComment": {
            "shape": "Sh"
          },
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "identifier": {
            "location": "uri",
            "locationName": "identifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "createdAt",
          "createdBy",
          "domainId",
          "id",
          "requestReason",
          "status",
          "subscribedListings",
          "subscribedPrincipals",
          "updatedAt"
        ],
        "members": {
          "createdAt": {
            "type": "timestamp"
          },
          "createdBy": {},
          "decisionComment": {
            "shape": "Sh"
          },
          "domainId": {},
          "id": {},
          "requestReason": {
            "shape": "Sm"
          },
          "reviewerId": {},
          "status": {},
          "subscribedListings": {
            "type": "list",
            "member": {
              "shape": "Sp"
            }
          },
          "subscribedPrincipals": {
            "type": "list",
            "member": {
              "shape": "S13"
            }
          },
          "updatedAt": {
            "type": "timestamp"
          },
          "updatedBy": {}
        }
      },
      "idempotent": true
    },
    "AssociateEnvironmentRole": {
      "http": {
        "method": "PUT",
        "requestUri": "/v2/domains/{domainIdentifier}/environments/{environmentIdentifier}/roles/{environmentRoleArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "environmentIdentifier",
          "environmentRoleArn"
        ],
        "members": {
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "environmentIdentifier": {
            "location": "uri",
            "locationName": "environmentIdentifier"
          },
          "environmentRoleArn": {
            "location": "uri",
            "locationName": "environmentRoleArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CancelMetadataGenerationRun": {
      "http": {
        "requestUri": "/v2/domains/{domainIdentifier}/metadata-generation-runs/{identifier}/cancel",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "identifier"
        ],
        "members": {
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "identifier": {
            "location": "uri",
            "locationName": "identifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CancelSubscription": {
      "http": {
        "method": "PUT",
        "requestUri": "/v2/domains/{domainIdentifier}/subscriptions/{identifier}/cancel",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "identifier"
        ],
        "members": {
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "identifier": {
            "location": "uri",
            "locationName": "identifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "createdAt",
          "createdBy",
          "domainId",
          "id",
          "status",
          "subscribedListing",
          "subscribedPrincipal",
          "updatedAt"
        ],
        "members": {
          "createdAt": {
            "type": "timestamp"
          },
          "createdBy": {},
          "domainId": {},
          "id": {},
          "retainPermissions": {
            "type": "boolean"
          },
          "status": {},
          "subscribedListing": {
            "shape": "Sp"
          },
          "subscribedPrincipal": {
            "shape": "S13"
          },
          "subscriptionRequestId": {},
          "updatedAt": {
            "type": "timestamp"
          },
          "updatedBy": {}
        }
      },
      "idempotent": true
    },
    "CreateAsset": {
      "http": {
        "requestUri": "/v2/domains/{domainIdentifier}/assets",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "name",
          "owningProjectIdentifier",
          "typeIdentifier"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "description": {
            "shape": "Sq"
          },
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "externalIdentifier": {
            "shape": "S1k"
          },
          "formsInput": {
            "shape": "S1l"
          },
          "glossaryTerms": {
            "shape": "S1q"
          },
          "name": {
            "shape": "S1s"
          },
          "owningProjectIdentifier": {},
          "predictionConfiguration": {
            "shape": "S1t"
          },
          "typeIdentifier": {},
          "typeRevision": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "domainId",
          "formsOutput",
          "id",
          "name",
          "owningProjectId",
          "revision",
          "typeIdentifier",
          "typeRevision"
        ],
        "members": {
          "createdAt": {
            "type": "timestamp"
          },
          "createdBy": {},
          "description": {
            "shape": "Sq"
          },
          "domainId": {},
          "externalIdentifier": {
            "shape": "S1k"
          },
          "firstRevisionCreatedAt": {
            "type": "timestamp"
          },
          "firstRevisionCreatedBy": {},
          "formsOutput": {
            "shape": "S1x"
          },
          "glossaryTerms": {
            "shape": "S1q"
          },
          "id": {},
          "latestTimeSeriesDataPointFormsOutput": {
            "shape": "S20"
          },
          "listing": {
            "shape": "S26"
          },
          "name": {
            "shape": "S1s"
          },
          "owningProjectId": {},
          "predictionConfiguration": {
            "shape": "S1t"
          },
          "readOnlyFormsOutput": {
            "shape": "S1x"
          },
          "revision": {},
          "typeIdentifier": {},
          "typeRevision": {}
        }
      },
      "idempotent": true
    },
    "CreateAssetFilter": {
      "http": {
        "requestUri": "/v2/domains/{domainIdentifier}/assets/{assetIdentifier}/filters",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "assetIdentifier",
          "configuration",
          "domainIdentifier",
          "name"
        ],
        "members": {
          "assetIdentifier": {
            "location": "uri",
            "locationName": "assetIdentifier"
          },
          "clientToken": {
            "idempotencyToken": true
          },
          "configuration": {
            "shape": "S29"
          },
          "description": {
            "shape": "Sq"
          },
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "name": {
            "shape": "S2t"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "assetId",
          "configuration",
          "domainId",
          "id",
          "name"
        ],
        "members": {
          "assetId": {},
          "configuration": {
            "shape": "S29"
          },
          "createdAt": {
            "type": "timestamp"
          },
          "description": {
            "shape": "Sq"
          },
          "domainId": {},
          "effectiveColumnNames": {
            "shape": "S2b"
          },
          "effectiveRowFilter": {},
          "errorMessage": {},
          "id": {},
          "name": {
            "shape": "S2t"
          },
          "status": {}
        }
      },
      "idempotent": true
    },
    "CreateAssetRevision": {
      "http": {
        "requestUri": "/v2/domains/{domainIdentifier}/assets/{identifier}/revisions",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "identifier",
          "name"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "description": {
            "shape": "Sq"
          },
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "formsInput": {
            "shape": "S1l"
          },
          "glossaryTerms": {
            "shape": "S1q"
          },
          "identifier": {
            "location": "uri",
            "locationName": "identifier"
          },
          "name": {
            "shape": "S1s"
          },
          "predictionConfiguration": {
            "shape": "S1t"
          },
          "typeRevision": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "domainId",
          "formsOutput",
          "id",
          "name",
          "owningProjectId",
          "revision",
          "typeIdentifier",
          "typeRevision"
        ],
        "members": {
          "createdAt": {
            "type": "timestamp"
          },
          "createdBy": {},
          "description": {
            "shape": "Sq"
          },
          "domainId": {},
          "externalIdentifier": {
            "shape": "S1k"
          },
          "firstRevisionCreatedAt": {
            "type": "timestamp"
          },
          "firstRevisionCreatedBy": {},
          "formsOutput": {
            "shape": "S1x"
          },
          "glossaryTerms": {
            "shape": "S1q"
          },
          "id": {},
          "latestTimeSeriesDataPointFormsOutput": {
            "shape": "S20"
          },
          "listing": {
            "shape": "S26"
          },
          "name": {
            "shape": "S1s"
          },
          "owningProjectId": {},
          "predictionConfiguration": {
            "shape": "S1t"
          },
          "readOnlyFormsOutput": {
            "shape": "S1x"
          },
          "revision": {},
          "typeIdentifier": {},
          "typeRevision": {}
        }
      },
      "idempotent": true
    },
    "CreateAssetType": {
      "http": {
        "requestUri": "/v2/domains/{domainIdentifier}/asset-types",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "formsInput",
          "name",
          "owningProjectIdentifier"
        ],
        "members": {
          "description": {
            "shape": "Sq"
          },
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "formsInput": {
            "type": "map",
            "key": {},
            "value": {
              "type": "structure",
              "required": [
                "typeIdentifier",
                "typeRevision"
              ],
              "members": {
                "required": {
                  "type": "boolean"
                },
                "typeIdentifier": {},
                "typeRevision": {}
              }
            }
          },
          "name": {},
          "owningProjectIdentifier": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "domainId",
          "formsOutput",
          "name",
          "revision"
        ],
        "members": {
          "createdAt": {
            "type": "timestamp"
          },
          "createdBy": {},
          "description": {
            "shape": "Sq"
          },
          "domainId": {},
          "formsOutput": {
            "shape": "S33"
          },
          "name": {},
          "originDomainId": {},
          "originProjectId": {},
          "owningProjectId": {},
          "revision": {},
          "updatedAt": {
            "type": "timestamp"
          },
          "updatedBy": {}
        }
      }
    },
    "CreateDataSource": {
      "http": {
        "requestUri": "/v2/domains/{domainIdentifier}/data-sources",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "environmentIdentifier",
          "name",
          "projectIdentifier",
          "type"
        ],
        "members": {
          "assetFormsInput": {
            "shape": "S1l"
          },
          "clientToken": {
            "idempotencyToken": true
          },
          "configuration": {
            "shape": "S36"
          },
          "description": {
            "shape": "Sq"
          },
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "enableSetting": {},
          "environmentIdentifier": {},
          "name": {
            "shape": "S3r"
          },
          "projectIdentifier": {},
          "publishOnImport": {
            "type": "boolean"
          },
          "recommendation": {
            "shape": "S3s"
          },
          "schedule": {
            "shape": "S3t"
          },
          "type": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "domainId",
          "environmentId",
          "id",
          "name",
          "projectId"
        ],
        "members": {
          "assetFormsOutput": {
            "shape": "S1x"
          },
          "configuration": {
            "shape": "S3y"
          },
          "createdAt": {
            "shape": "S47"
          },
          "description": {
            "shape": "Sq"
          },
          "domainId": {},
          "enableSetting": {},
          "environmentId": {},
          "errorMessage": {
            "shape": "S48"
          },
          "id": {},
          "lastRunAt": {
            "shape": "S47"
          },
          "lastRunErrorMessage": {
            "shape": "S48"
          },
          "lastRunStatus": {},
          "name": {
            "shape": "S3r"
          },
          "projectId": {},
          "publishOnImport": {
            "type": "boolean"
          },
          "recommendation": {
            "shape": "S3s"
          },
          "schedule": {
            "shape": "S3t"
          },
          "status": {},
          "type": {},
          "updatedAt": {
            "shape": "S47"
          }
        }
      },
      "idempotent": true
    },
    "CreateDomain": {
      "http": {
        "requestUri": "/v2/domains",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "domainExecutionRole",
          "name"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "description": {},
          "domainExecutionRole": {},
          "kmsKeyIdentifier": {},
          "name": {},
          "singleSignOn": {
            "shape": "S4g"
          },
          "tags": {
            "shape": "S4j"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "id"
        ],
        "members": {
          "arn": {},
          "description": {},
          "domainExecutionRole": {},
          "id": {},
          "kmsKeyIdentifier": {},
          "name": {},
          "portalUrl": {},
          "singleSignOn": {
            "shape": "S4g"
          },
          "status": {},
          "tags": {
            "shape": "S4j"
          }
        }
      },
      "idempotent": true
    },
    "CreateEnvironment": {
      "http": {
        "requestUri": "/v2/domains/{domainIdentifier}/environments",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "environmentProfileIdentifier",
          "name",
          "projectIdentifier"
        ],
        "members": {
          "description": {},
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "environmentAccountIdentifier": {},
          "environmentAccountRegion": {},
          "environmentBlueprintIdentifier": {},
          "environmentProfileIdentifier": {},
          "glossaryTerms": {
            "shape": "S1q"
          },
          "name": {},
          "projectIdentifier": {},
          "userParameters": {
            "shape": "S4q"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "createdBy",
          "domainId",
          "name",
          "projectId",
          "provider"
        ],
        "members": {
          "awsAccountId": {},
          "awsAccountRegion": {},
          "createdAt": {
            "shape": "S4v"
          },
          "createdBy": {},
          "deploymentProperties": {
            "shape": "S4w"
          },
          "description": {
            "shape": "Sq"
          },
          "domainId": {},
          "environmentActions": {
            "shape": "S4z"
          },
          "environmentBlueprintId": {},
          "environmentProfileId": {},
          "glossaryTerms": {
            "shape": "S1q"
          },
          "id": {},
          "lastDeployment": {
            "shape": "S55"
          },
          "name": {
            "shape": "S5b"
          },
          "projectId": {},
          "provider": {},
          "provisionedResources": {
            "shape": "S5c"
          },
          "provisioningProperties": {
            "shape": "S5e"
          },
          "status": {},
          "updatedAt": {
            "shape": "S4v"
          },
          "userParameters": {
            "shape": "S5h"
          }
        }
      }
    },
    "CreateEnvironmentAction": {
      "http": {
        "requestUri": "/v2/domains/{domainIdentifier}/environments/{environmentIdentifier}/actions",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "environmentIdentifier",
          "name",
          "parameters"
        ],
        "members": {
          "description": {},
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "environmentIdentifier": {
            "location": "uri",
            "locationName": "environmentIdentifier"
          },
          "name": {},
          "parameters": {
            "shape": "S5l"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "domainId",
          "environmentId",
          "id",
          "name",
          "parameters"
        ],
        "members": {
          "description": {},
          "domainId": {},
          "environmentId": {},
          "id": {},
          "name": {},
          "parameters": {
            "shape": "S5l"
          }
        }
      }
    },
    "CreateEnvironmentProfile": {
      "http": {
        "requestUri": "/v2/domains/{domainIdentifier}/environment-profiles",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "environmentBlueprintIdentifier",
          "name",
          "projectIdentifier"
        ],
        "members": {
          "awsAccountId": {},
          "awsAccountRegion": {},
          "description": {
            "shape": "Sq"
          },
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "environmentBlueprintIdentifier": {},
          "name": {
            "shape": "S5q"
          },
          "projectIdentifier": {},
          "userParameters": {
            "shape": "S4q"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "createdBy",
          "domainId",
          "environmentBlueprintId",
          "id",
          "name"
        ],
        "members": {
          "awsAccountId": {},
          "awsAccountRegion": {},
          "createdAt": {
            "shape": "S4v"
          },
          "createdBy": {},
          "description": {
            "shape": "Sq"
          },
          "domainId": {},
          "environmentBlueprintId": {},
          "id": {},
          "name": {
            "shape": "S5q"
          },
          "projectId": {},
          "updatedAt": {
            "shape": "S4v"
          },
          "userParameters": {
            "shape": "S5h"
          }
        }
      }
    },
    "CreateFormType": {
      "http": {
        "requestUri": "/v2/domains/{domainIdentifier}/form-types",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "model",
          "name",
          "owningProjectIdentifier"
        ],
        "members": {
          "description": {
            "shape": "Sq"
          },
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "model": {
            "shape": "S5t"
          },
          "name": {
            "shape": "S1z"
          },
          "owningProjectIdentifier": {},
          "status": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "domainId",
          "name",
          "revision"
        ],
        "members": {
          "description": {
            "shape": "Sq"
          },
          "domainId": {},
          "name": {
            "shape": "S1z"
          },
          "originDomainId": {},
          "originProjectId": {},
          "owningProjectId": {},
          "revision": {}
        }
      }
    },
    "CreateGlossary": {
      "http": {
        "requestUri": "/v2/domains/{domainIdentifier}/glossaries",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "name",
          "owningProjectIdentifier"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "description": {
            "shape": "S5y"
          },
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "name": {
            "shape": "S5z"
          },
          "owningProjectIdentifier": {},
          "status": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "domainId",
          "id",
          "name",
          "owningProjectId"
        ],
        "members": {
          "description": {
            "shape": "S5y"
          },
          "domainId": {},
          "id": {},
          "name": {
            "shape": "S5z"
          },
          "owningProjectId": {},
          "status": {}
        }
      },
      "idempotent": true
    },
    "CreateGlossaryTerm": {
      "http": {
        "requestUri": "/v2/domains/{domainIdentifier}/glossary-terms",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "glossaryIdentifier",
          "name"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "glossaryIdentifier": {},
          "longDescription": {
            "shape": "S64"
          },
          "name": {
            "shape": "Sy"
          },
          "shortDescription": {
            "shape": "Sz"
          },
          "status": {},
          "termRelations": {
            "shape": "S66"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "domainId",
          "glossaryId",
          "id",
          "name",
          "status"
        ],
        "members": {
          "domainId": {},
          "glossaryId": {},
          "id": {},
          "longDescription": {
            "shape": "S64"
          },
          "name": {
            "shape": "Sy"
          },
          "shortDescription": {
            "shape": "Sz"
          },
          "status": {},
          "termRelations": {
            "shape": "S66"
          }
        }
      },
      "idempotent": true
    },
    "CreateGroupProfile": {
      "http": {
        "requestUri": "/v2/domains/{domainIdentifier}/group-profiles",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "groupIdentifier"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "groupIdentifier": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "domainId": {},
          "groupName": {
            "shape": "S6d"
          },
          "id": {},
          "status": {}
        }
      },
      "idempotent": true
    },
    "CreateListingChangeSet": {
      "http": {
        "requestUri": "/v2/domains/{domainIdentifier}/listings/change-set",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "action",
          "domainIdentifier",
          "entityIdentifier",
          "entityType"
        ],
        "members": {
          "action": {},
          "clientToken": {
            "idempotencyToken": true
          },
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "entityIdentifier": {},
          "entityRevision": {},
          "entityType": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "listingId",
          "listingRevision",
          "status"
        ],
        "members": {
          "listingId": {},
          "listingRevision": {},
          "status": {}
        }
      }
    },
    "CreateProject": {
      "http": {
        "requestUri": "/v2/domains/{domainIdentifier}/projects",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "name"
        ],
        "members": {
          "description": {
            "shape": "Sq"
          },
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "glossaryTerms": {
            "shape": "S1q"
          },
          "name": {
            "shape": "S15"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "createdBy",
          "domainId",
          "id",
          "name"
        ],
        "members": {
          "createdAt": {
            "shape": "S4v"
          },
          "createdBy": {},
          "description": {
            "shape": "Sq"
          },
          "domainId": {},
          "failureReasons": {
            "shape": "S6n"
          },
          "glossaryTerms": {
            "shape": "S1q"
          },
          "id": {},
          "lastUpdatedAt": {
            "shape": "S4v"
          },
          "name": {
            "shape": "S15"
          },
          "projectStatus": {}
        }
      }
    },
    "CreateProjectMembership": {
      "http": {
        "requestUri": "/v2/domains/{domainIdentifier}/projects/{projectIdentifier}/createMembership",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "designation",
          "domainIdentifier",
          "member",
          "projectIdentifier"
        ],
        "members": {
          "designation": {},
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "member": {
            "shape": "S6s"
          },
          "projectIdentifier": {
            "location": "uri",
            "locationName": "projectIdentifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CreateSubscriptionGrant": {
      "http": {
        "requestUri": "/v2/domains/{domainIdentifier}/subscription-grants",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "environmentIdentifier",
          "grantedEntity",
          "subscriptionTargetIdentifier"
        ],
        "members": {
          "assetTargetNames": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "assetId",
                "targetName"
              ],
              "members": {
                "assetId": {},
                "targetName": {}
              }
            }
          },
          "clientToken": {
            "idempotencyToken": true
          },
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "environmentIdentifier": {},
          "grantedEntity": {
            "type": "structure",
            "members": {
              "listing": {
                "type": "structure",
                "required": [
                  "identifier",
                  "revision"
                ],
                "members": {
                  "identifier": {},
                  "revision": {}
                }
              }
            },
            "union": true
          },
          "subscriptionTargetIdentifier": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "createdAt",
          "createdBy",
          "domainId",
          "grantedEntity",
          "id",
          "status",
          "subscriptionTargetId",
          "updatedAt"
        ],
        "members": {
          "assets": {
            "shape": "S71"
          },
          "createdAt": {
            "type": "timestamp"
          },
          "createdBy": {},
          "domainId": {},
          "grantedEntity": {
            "shape": "S75"
          },
          "id": {},
          "status": {},
          "subscriptionId": {},
          "subscriptionTargetId": {},
          "updatedAt": {
            "type": "timestamp"
          },
          "updatedBy": {}
        }
      }
    },
    "CreateSubscriptionRequest": {
      "http": {
        "requestUri": "/v2/domains/{domainIdentifier}/subscription-requests",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "requestReason",
          "subscribedListings",
          "subscribedPrincipals"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "requestReason": {
            "shape": "Sm"
          },
          "subscribedListings": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "identifier"
              ],
              "members": {
                "identifier": {}
              }
            }
          },
          "subscribedPrincipals": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "project": {
                  "type": "structure",
                  "members": {
                    "identifier": {}
                  }
                }
              },
              "union": true
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "createdAt",
          "createdBy",
          "domainId",
          "id",
          "requestReason",
          "status",
          "subscribedListings",
          "subscribedPrincipals",
          "updatedAt"
        ],
        "members": {
          "createdAt": {
            "type": "timestamp"
          },
          "createdBy": {},
          "decisionComment": {
            "shape": "Sh"
          },
          "domainId": {},
          "id": {},
          "requestReason": {
            "shape": "Sm"
          },
          "reviewerId": {},
          "status": {},
          "subscribedListings": {
            "type": "list",
            "member": {
              "shape": "Sp"
            }
          },
          "subscribedPrincipals": {
            "type": "list",
            "member": {
              "shape": "S13"
            }
          },
          "updatedAt": {
            "type": "timestamp"
          },
          "updatedBy": {}
        }
      }
    },
    "CreateSubscriptionTarget": {
      "http": {
        "requestUri": "/v2/domains/{domainIdentifier}/environments/{environmentIdentifier}/subscription-targets",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "applicableAssetTypes",
          "authorizedPrincipals",
          "domainIdentifier",
          "environmentIdentifier",
          "manageAccessRole",
          "name",
          "subscriptionTargetConfig",
          "type"
        ],
        "members": {
          "applicableAssetTypes": {
            "shape": "S7j"
          },
          "authorizedPrincipals": {
            "shape": "S7k"
          },
          "clientToken": {
            "idempotencyToken": true
          },
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "environmentIdentifier": {
            "location": "uri",
            "locationName": "environmentIdentifier"
          },
          "manageAccessRole": {},
          "name": {
            "shape": "S7m"
          },
          "provider": {},
          "subscriptionTargetConfig": {
            "shape": "S7n"
          },
          "type": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "applicableAssetTypes",
          "authorizedPrincipals",
          "createdAt",
          "createdBy",
          "domainId",
          "environmentId",
          "id",
          "manageAccessRole",
          "name",
          "projectId",
          "provider",
          "subscriptionTargetConfig",
          "type"
        ],
        "members": {
          "applicableAssetTypes": {
            "shape": "S7j"
          },
          "authorizedPrincipals": {
            "shape": "S7k"
          },
          "createdAt": {
            "type": "timestamp"
          },
          "createdBy": {},
          "domainId": {},
          "environmentId": {},
          "id": {},
          "manageAccessRole": {},
          "name": {
            "shape": "S7m"
          },
          "projectId": {},
          "provider": {},
          "subscriptionTargetConfig": {
            "shape": "S7n"
          },
          "type": {},
          "updatedAt": {
            "type": "timestamp"
          },
          "updatedBy": {}
        }
      }
    },
    "CreateUserProfile": {
      "http": {
        "requestUri": "/v2/domains/{domainIdentifier}/user-profiles",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "userIdentifier"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "userIdentifier": {},
          "userType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "details": {
            "shape": "S7u"
          },
          "domainId": {},
          "id": {},
          "status": {},
          "type": {}
        }
      },
      "idempotent": true
    },
    "DeleteAsset": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v2/domains/{domainIdentifier}/assets/{identifier}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "identifier"
        ],
        "members": {
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "identifier": {
            "location": "uri",
            "locationName": "identifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeleteAssetFilter": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v2/domains/{domainIdentifier}/assets/{assetIdentifier}/filters/{identifier}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "assetIdentifier",
          "domainIdentifier",
          "identifier"
        ],
        "members": {
          "assetIdentifier": {
            "location": "uri",
            "locationName": "assetIdentifier"
          },
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "identifier": {
            "location": "uri",
            "locationName": "identifier"
          }
        }
      },
      "idempotent": true
    },
    "DeleteAssetType": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v2/domains/{domainIdentifier}/asset-types/{identifier}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "identifier"
        ],
        "members": {
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "identifier": {
            "location": "uri",
            "locationName": "identifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteDataSource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v2/domains/{domainIdentifier}/data-sources/{identifier}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "identifier"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true,
            "location": "querystring",
            "locationName": "clientToken"
          },
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "identifier": {
            "location": "uri",
            "locationName": "identifier"
          },
          "retainPermissionsOnRevokeFailure": {
            "location": "querystring",
            "locationName": "retainPermissionsOnRevokeFailure",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "domainId",
          "environmentId",
          "id",
          "name",
          "projectId"
        ],
        "members": {
          "assetFormsOutput": {
            "shape": "S1x"
          },
          "configuration": {
            "shape": "S3y"
          },
          "createdAt": {
            "shape": "S47"
          },
          "description": {
            "shape": "Sq"
          },
          "domainId": {},
          "enableSetting": {},
          "environmentId": {},
          "errorMessage": {
            "shape": "S48"
          },
          "id": {},
          "lastRunAt": {
            "shape": "S47"
          },
          "lastRunErrorMessage": {
            "shape": "S48"
          },
          "lastRunStatus": {},
          "name": {
            "shape": "S3r"
          },
          "projectId": {},
          "publishOnImport": {
            "type": "boolean"
          },
          "retainPermissionsOnRevokeFailure": {
            "type": "boolean"
          },
          "schedule": {
            "shape": "S3t"
          },
          "selfGrantStatus": {
            "shape": "S8a"
          },
          "status": {},
          "type": {},
          "updatedAt": {
            "shape": "S47"
          }
        }
      },
      "idempotent": true
    },
    "DeleteDomain": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v2/domains/{identifier}",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "identifier"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true,
            "location": "querystring",
            "locationName": "clientToken"
          },
          "identifier": {
            "location": "uri",
            "locationName": "identifier"
          },
          "skipDeletionCheck": {
            "location": "querystring",
            "locationName": "skipDeletionCheck",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "status"
        ],
        "members": {
          "status": {}
        }
      },
      "idempotent": true
    },
    "DeleteEnvironment": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v2/domains/{domainIdentifier}/environments/{identifier}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "identifier"
        ],
        "members": {
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "identifier": {
            "location": "uri",
            "locationName": "identifier"
          }
        }
      },
      "idempotent": true
    },
    "DeleteEnvironmentAction": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v2/domains/{domainIdentifier}/environments/{environmentIdentifier}/actions/{identifier}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "environmentIdentifier",
          "identifier"
        ],
        "members": {
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "environmentIdentifier": {
            "location": "uri",
            "locationName": "environmentIdentifier"
          },
          "identifier": {
            "location": "uri",
            "locationName": "identifier"
          }
        }
      },
      "idempotent": true
    },
    "DeleteEnvironmentBlueprintConfiguration": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v2/domains/{domainIdentifier}/environment-blueprint-configurations/{environmentBlueprintIdentifier}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "environmentBlueprintIdentifier"
        ],
        "members": {
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "environmentBlueprintIdentifier": {
            "location": "uri",
            "locationName": "environmentBlueprintIdentifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeleteEnvironmentProfile": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v2/domains/{domainIdentifier}/environment-profiles/{identifier}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "identifier"
        ],
        "members": {
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "identifier": {
            "location": "uri",
            "locationName": "identifier"
          }
        }
      },
      "idempotent": true
    },
    "DeleteFormType": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v2/domains/{domainIdentifier}/form-types/{formTypeIdentifier}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "formTypeIdentifier"
        ],
        "members": {
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "formTypeIdentifier": {
            "location": "uri",
            "locationName": "formTypeIdentifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteGlossary": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v2/domains/{domainIdentifier}/glossaries/{identifier}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "identifier"
        ],
        "members": {
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "identifier": {
            "location": "uri",
            "locationName": "identifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeleteGlossaryTerm": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v2/domains/{domainIdentifier}/glossary-terms/{identifier}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "identifier"
        ],
        "members": {
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "identifier": {
            "location": "uri",
            "locationName": "identifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeleteListing": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v2/domains/{domainIdentifier}/listings/{identifier}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "identifier"
        ],
        "members": {
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "identifier": {
            "location": "uri",
            "locationName": "identifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeleteProject": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v2/domains/{domainIdentifier}/projects/{identifier}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "identifier"
        ],
        "members": {
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "identifier": {
            "location": "uri",
            "locationName": "identifier"
          },
          "skipDeletionCheck": {
            "location": "querystring",
            "locationName": "skipDeletionCheck",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeleteProjectMembership": {
      "http": {
        "requestUri": "/v2/domains/{domainIdentifier}/projects/{projectIdentifier}/deleteMembership",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "member",
          "projectIdentifier"
        ],
        "members": {
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "member": {
            "shape": "S6s"
          },
          "projectIdentifier": {
            "location": "uri",
            "locationName": "projectIdentifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeleteSubscriptionGrant": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v2/domains/{domainIdentifier}/subscription-grants/{identifier}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "identifier"
        ],
        "members": {
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "identifier": {
            "location": "uri",
            "locationName": "identifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "createdAt",
          "createdBy",
          "domainId",
          "grantedEntity",
          "id",
          "status",
          "subscriptionTargetId",
          "updatedAt"
        ],
        "members": {
          "assets": {
            "shape": "S71"
          },
          "createdAt": {
            "type": "timestamp"
          },
          "createdBy": {},
          "domainId": {},
          "grantedEntity": {
            "shape": "S75"
          },
          "id": {},
          "status": {},
          "subscriptionId": {},
          "subscriptionTargetId": {},
          "updatedAt": {
            "type": "timestamp"
          },
          "updatedBy": {}
        }
      }
    },
    "DeleteSubscriptionRequest": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v2/domains/{domainIdentifier}/subscription-requests/{identifier}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "identifier"
        ],
        "members": {
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "identifier": {
            "location": "uri",
            "locationName": "identifier"
          }
        }
      }
    },
    "DeleteSubscriptionTarget": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v2/domains/{domainIdentifier}/environments/{environmentIdentifier}/subscription-targets/{identifier}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "environmentIdentifier",
          "identifier"
        ],
        "members": {
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "environmentIdentifier": {
            "location": "uri",
            "locationName": "environmentIdentifier"
          },
          "identifier": {
            "location": "uri",
            "locationName": "identifier"
          }
        }
      }
    },
    "DeleteTimeSeriesDataPoints": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v2/domains/{domainIdentifier}/entities/{entityType}/{entityIdentifier}/time-series-data-points",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "entityIdentifier",
          "entityType",
          "formName"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true,
            "location": "querystring",
            "locationName": "clientToken"
          },
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "entityIdentifier": {
            "location": "uri",
            "locationName": "entityIdentifier"
          },
          "entityType": {
            "location": "uri",
            "locationName": "entityType"
          },
          "formName": {
            "location": "querystring",
            "locationName": "formName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DisassociateEnvironmentRole": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v2/domains/{domainIdentifier}/environments/{environmentIdentifier}/roles/{environmentRoleArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "environmentIdentifier",
          "environmentRoleArn"
        ],
        "members": {
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "environmentIdentifier": {
            "location": "uri",
            "locationName": "environmentIdentifier"
          },
          "environmentRoleArn": {
            "location": "uri",
            "locationName": "environmentRoleArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "GetAsset": {
      "http": {
        "method": "GET",
        "requestUri": "/v2/domains/{domainIdentifier}/assets/{identifier}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "identifier"
        ],
        "members": {
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "identifier": {
            "location": "uri",
            "locationName": "identifier"
          },
          "revision": {
            "location": "querystring",
            "locationName": "revision"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "domainId",
          "formsOutput",
          "id",
          "name",
          "owningProjectId",
          "revision",
          "typeIdentifier",
          "typeRevision"
        ],
        "members": {
          "createdAt": {
            "type": "timestamp"
          },
          "createdBy": {},
          "description": {
            "shape": "Sq"
          },
          "domainId": {},
          "externalIdentifier": {
            "shape": "S1k"
          },
          "firstRevisionCreatedAt": {
            "type": "timestamp"
          },
          "firstRevisionCreatedBy": {},
          "formsOutput": {
            "shape": "S1x"
          },
          "glossaryTerms": {
            "shape": "S1q"
          },
          "id": {},
          "latestTimeSeriesDataPointFormsOutput": {
            "shape": "S20"
          },
          "listing": {
            "shape": "S26"
          },
          "name": {
            "shape": "S1s"
          },
          "owningProjectId": {},
          "readOnlyFormsOutput": {
            "shape": "S1x"
          },
          "revision": {},
          "typeIdentifier": {},
          "typeRevision": {}
        }
      }
    },
    "GetAssetFilter": {
      "http": {
        "method": "GET",
        "requestUri": "/v2/domains/{domainIdentifier}/assets/{assetIdentifier}/filters/{identifier}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "assetIdentifier",
          "domainIdentifier",
          "identifier"
        ],
        "members": {
          "assetIdentifier": {
            "location": "uri",
            "locationName": "assetIdentifier"
          },
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "identifier": {
            "location": "uri",
            "locationName": "identifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "assetId",
          "configuration",
          "domainId",
          "id",
          "name"
        ],
        "members": {
          "assetId": {},
          "configuration": {
            "shape": "S29"
          },
          "createdAt": {
            "type": "timestamp"
          },
          "description": {
            "shape": "Sq"
          },
          "domainId": {},
          "effectiveColumnNames": {
            "shape": "S2b"
          },
          "effectiveRowFilter": {},
          "errorMessage": {},
          "id": {},
          "name": {
            "shape": "S2t"
          },
          "status": {}
        }
      }
    },
    "GetAssetType": {
      "http": {
        "method": "GET",
        "requestUri": "/v2/domains/{domainIdentifier}/asset-types/{identifier}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "identifier"
        ],
        "members": {
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "identifier": {
            "location": "uri",
            "locationName": "identifier"
          },
          "revision": {
            "location": "querystring",
            "locationName": "revision"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "domainId",
          "formsOutput",
          "name",
          "owningProjectId",
          "revision"
        ],
        "members": {
          "createdAt": {
            "type": "timestamp"
          },
          "createdBy": {},
          "description": {
            "shape": "Sq"
          },
          "domainId": {},
          "formsOutput": {
            "shape": "S33"
          },
          "name": {},
          "originDomainId": {},
          "originProjectId": {},
          "owningProjectId": {},
          "revision": {},
          "updatedAt": {
            "type": "timestamp"
          },
          "updatedBy": {}
        }
      }
    },
    "GetDataSource": {
      "http": {
        "method": "GET",
        "requestUri": "/v2/domains/{domainIdentifier}/data-sources/{identifier}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "identifier"
        ],
        "members": {
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "identifier": {
            "location": "uri",
            "locationName": "identifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "domainId",
          "environmentId",
          "id",
          "name",
          "projectId"
        ],
        "members": {
          "assetFormsOutput": {
            "shape": "S1x"
          },
          "configuration": {
            "shape": "S3y"
          },
          "createdAt": {
            "shape": "S47"
          },
          "description": {
            "shape": "Sq"
          },
          "domainId": {},
          "enableSetting": {},
          "environmentId": {},
          "errorMessage": {
            "shape": "S48"
          },
          "id": {},
          "lastRunAssetCount": {
            "type": "integer"
          },
          "lastRunAt": {
            "shape": "S47"
          },
          "lastRunErrorMessage": {
            "shape": "S48"
          },
          "lastRunStatus": {},
          "name": {
            "shape": "S3r"
          },
          "projectId": {},
          "publishOnImport": {
            "type": "boolean"
          },
          "recommendation": {
            "shape": "S3s"
          },
          "schedule": {
            "shape": "S3t"
          },
          "selfGrantStatus": {
            "shape": "S8a"
          },
          "status": {},
          "type": {},
          "updatedAt": {
            "shape": "S47"
          }
        }
      }
    },
    "GetDataSourceRun": {
      "http": {
        "method": "GET",
        "requestUri": "/v2/domains/{domainIdentifier}/data-source-runs/{identifier}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "identifier"
        ],
        "members": {
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "identifier": {
            "location": "uri",
            "locationName": "identifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "createdAt",
          "dataSourceId",
          "domainId",
          "id",
          "projectId",
          "status",
          "type",
          "updatedAt"
        ],
        "members": {
          "createdAt": {
            "shape": "S47"
          },
          "dataSourceConfigurationSnapshot": {},
          "dataSourceId": {},
          "domainId": {},
          "errorMessage": {
            "shape": "S48"
          },
          "id": {},
          "projectId": {},
          "runStatisticsForAssets": {
            "shape": "S9l"
          },
          "startedAt": {
            "shape": "S47"
          },
          "status": {},
          "stoppedAt": {
            "shape": "S47"
          },
          "type": {},
          "updatedAt": {
            "shape": "S47"
          }
        }
      }
    },
    "GetDomain": {
      "http": {
        "method": "GET",
        "requestUri": "/v2/domains/{identifier}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "identifier"
        ],
        "members": {
          "identifier": {
            "location": "uri",
            "locationName": "identifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "domainExecutionRole",
          "id",
          "status"
        ],
        "members": {
          "arn": {},
          "createdAt": {
            "type": "timestamp"
          },
          "description": {},
          "domainExecutionRole": {},
          "id": {},
          "kmsKeyIdentifier": {},
          "lastUpdatedAt": {
            "type": "timestamp"
          },
          "name": {},
          "portalUrl": {},
          "singleSignOn": {
            "shape": "S4g"
          },
          "status": {},
          "tags": {
            "shape": "S4j"
          }
        }
      }
    },
    "GetEnvironment": {
      "http": {
        "method": "GET",
        "requestUri": "/v2/domains/{domainIdentifier}/environments/{identifier}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "identifier"
        ],
        "members": {
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "identifier": {
            "location": "uri",
            "locationName": "identifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "createdBy",
          "domainId",
          "name",
          "projectId",
          "provider"
        ],
        "members": {
          "awsAccountId": {},
          "awsAccountRegion": {},
          "createdAt": {
            "shape": "S4v"
          },
          "createdBy": {},
          "deploymentProperties": {
            "shape": "S4w"
          },
          "description": {
            "shape": "Sq"
          },
          "domainId": {},
          "environmentActions": {
            "shape": "S4z"
          },
          "environmentBlueprintId": {},
          "environmentProfileId": {},
          "glossaryTerms": {
            "shape": "S1q"
          },
          "id": {},
          "lastDeployment": {
            "shape": "S55"
          },
          "name": {
            "shape": "S5b"
          },
          "projectId": {},
          "provider": {},
          "provisionedResources": {
            "shape": "S5c"
          },
          "provisioningProperties": {
            "shape": "S5e"
          },
          "status": {},
          "updatedAt": {
            "shape": "S4v"
          },
          "userParameters": {
            "shape": "S5h"
          }
        }
      }
    },
    "GetEnvironmentAction": {
      "http": {
        "method": "GET",
        "requestUri": "/v2/domains/{domainIdentifier}/environments/{environmentIdentifier}/actions/{identifier}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "environmentIdentifier",
          "identifier"
        ],
        "members": {
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "environmentIdentifier": {
            "location": "uri",
            "locationName": "environmentIdentifier"
          },
          "identifier": {
            "location": "uri",
            "locationName": "identifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "domainId",
          "environmentId",
          "id",
          "name",
          "parameters"
        ],
        "members": {
          "description": {},
          "domainId": {},
          "environmentId": {},
          "id": {},
          "name": {},
          "parameters": {
            "shape": "S5l"
          }
        }
      }
    },
    "GetEnvironmentBlueprint": {
      "http": {
        "method": "GET",
        "requestUri": "/v2/domains/{domainIdentifier}/environment-blueprints/{identifier}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "identifier"
        ],
        "members": {
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "identifier": {
            "location": "uri",
            "locationName": "identifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "id",
          "name",
          "provider",
          "provisioningProperties"
        ],
        "members": {
          "createdAt": {
            "shape": "S4v"
          },
          "deploymentProperties": {
            "shape": "S4w"
          },
          "description": {
            "shape": "Sq"
          },
          "glossaryTerms": {
            "shape": "S1q"
          },
          "id": {},
          "name": {},
          "provider": {},
          "provisioningProperties": {
            "shape": "S5e"
          },
          "updatedAt": {
            "shape": "S4v"
          },
          "userParameters": {
            "shape": "S5h"
          }
        }
      }
    },
    "GetEnvironmentBlueprintConfiguration": {
      "http": {
        "method": "GET",
        "requestUri": "/v2/domains/{domainIdentifier}/environment-blueprint-configurations/{environmentBlueprintIdentifier}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "environmentBlueprintIdentifier"
        ],
        "members": {
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "environmentBlueprintIdentifier": {
            "location": "uri",
            "locationName": "environmentBlueprintIdentifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "domainId",
          "environmentBlueprintId"
        ],
        "members": {
          "createdAt": {
            "shape": "S4v"
          },
          "domainId": {},
          "enabledRegions": {
            "shape": "S9y"
          },
          "environmentBlueprintId": {},
          "manageAccessRoleArn": {},
          "provisioningConfigurations": {
            "shape": "Sa0"
          },
          "provisioningRoleArn": {},
          "regionalParameters": {
            "shape": "Sa5"
          },
          "updatedAt": {
            "shape": "S4v"
          }
        }
      }
    },
    "GetEnvironmentCredentials": {
      "http": {
        "method": "GET",
        "requestUri": "/v2/domains/{domainIdentifier}/environments/{environmentIdentifier}/credentials",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "environmentIdentifier"
        ],
        "members": {
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "environmentIdentifier": {
            "location": "uri",
            "locationName": "environmentIdentifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "accessKeyId": {},
          "expiration": {
            "shape": "S4v"
          },
          "secretAccessKey": {},
          "sessionToken": {}
        },
        "sensitive": true
      }
    },
    "GetEnvironmentProfile": {
      "http": {
        "method": "GET",
        "requestUri": "/v2/domains/{domainIdentifier}/environment-profiles/{identifier}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "identifier"
        ],
        "members": {
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "identifier": {
            "location": "uri",
            "locationName": "identifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "createdBy",
          "domainId",
          "environmentBlueprintId",
          "id",
          "name"
        ],
        "members": {
          "awsAccountId": {},
          "awsAccountRegion": {},
          "createdAt": {
            "shape": "S4v"
          },
          "createdBy": {},
          "description": {
            "shape": "Sq"
          },
          "domainId": {},
          "environmentBlueprintId": {},
          "id": {},
          "name": {
            "shape": "S5q"
          },
          "projectId": {},
          "updatedAt": {
            "shape": "S4v"
          },
          "userParameters": {
            "shape": "S5h"
          }
        }
      }
    },
    "GetFormType": {
      "http": {
        "method": "GET",
        "requestUri": "/v2/domains/{domainIdentifier}/form-types/{formTypeIdentifier}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "formTypeIdentifier"
        ],
        "members": {
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "formTypeIdentifier": {
            "location": "uri",
            "locationName": "formTypeIdentifier"
          },
          "revision": {
            "location": "querystring",
            "locationName": "revision"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "domainId",
          "model",
          "name",
          "revision"
        ],
        "members": {
          "createdAt": {
            "type": "timestamp"
          },
          "createdBy": {},
          "description": {
            "shape": "Sq"
          },
          "domainId": {},
          "imports": {
            "shape": "Sad"
          },
          "model": {
            "shape": "S5t"
          },
          "name": {
            "shape": "S1z"
          },
          "originDomainId": {},
          "originProjectId": {},
          "owningProjectId": {},
          "revision": {},
          "status": {}
        }
      }
    },
    "GetGlossary": {
      "http": {
        "method": "GET",
        "requestUri": "/v2/domains/{domainIdentifier}/glossaries/{identifier}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "identifier"
        ],
        "members": {
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "identifier": {
            "location": "uri",
            "locationName": "identifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "domainId",
          "id",
          "name",
          "owningProjectId",
          "status"
        ],
        "members": {
          "createdAt": {
            "type": "timestamp"
          },
          "createdBy": {},
          "description": {
            "shape": "S5y"
          },
          "domainId": {},
          "id": {},
          "name": {
            "shape": "S5z"
          },
          "owningProjectId": {},
          "status": {},
          "updatedAt": {
            "type": "timestamp"
          },
          "updatedBy": {}
        }
      }
    },
    "GetGlossaryTerm": {
      "http": {
        "method": "GET",
        "requestUri": "/v2/domains/{domainIdentifier}/glossary-terms/{identifier}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "identifier"
        ],
        "members": {
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "identifier": {
            "location": "uri",
            "locationName": "identifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "domainId",
          "glossaryId",
          "id",
          "name",
          "status"
        ],
        "members": {
          "createdAt": {
            "type": "timestamp"
          },
          "createdBy": {},
          "domainId": {},
          "glossaryId": {},
          "id": {},
          "longDescription": {
            "shape": "S64"
          },
          "name": {
            "shape": "Sy"
          },
          "shortDescription": {
            "shape": "Sz"
          },
          "status": {},
          "termRelations": {
            "shape": "S66"
          },
          "updatedAt": {
            "type": "timestamp"
          },
          "updatedBy": {}
        }
      }
    },
    "GetGroupProfile": {
      "http": {
        "method": "GET",
        "requestUri": "/v2/domains/{domainIdentifier}/group-profiles/{groupIdentifier}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "groupIdentifier"
        ],
        "members": {
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "groupIdentifier": {
            "location": "uri",
            "locationName": "groupIdentifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "domainId": {},
          "groupName": {
            "shape": "S6d"
          },
          "id": {},
          "status": {}
        }
      }
    },
    "GetIamPortalLoginUrl": {
      "http": {
        "requestUri": "/v2/domains/{domainIdentifier}/get-portal-login-url",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier"
        ],
        "members": {
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "userProfileId"
        ],
        "members": {
          "authCodeUrl": {},
          "userProfileId": {}
        }
      }
    },
    "GetLineageNode": {
      "http": {
        "method": "GET",
        "requestUri": "/v2/domains/{domainIdentifier}/lineage/nodes/{identifier}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "identifier"
        ],
        "members": {
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "eventTimestamp": {
            "location": "querystring",
            "locationName": "timestamp",
            "type": "timestamp"
          },
          "identifier": {
            "location": "uri",
            "locationName": "identifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "domainId",
          "id",
          "typeName"
        ],
        "members": {
          "createdAt": {
            "type": "timestamp"
          },
          "createdBy": {},
          "description": {},
          "domainId": {},
          "downstreamNodes": {
            "shape": "Saq"
          },
          "eventTimestamp": {
            "type": "timestamp"
          },
          "formsOutput": {
            "shape": "S1x"
          },
          "id": {},
          "name": {},
          "sourceIdentifier": {},
          "typeName": {},
          "typeRevision": {},
          "updatedAt": {
            "type": "timestamp"
          },
          "updatedBy": {},
          "upstreamNodes": {
            "shape": "Saq"
          }
        }
      }
    },
    "GetListing": {
      "http": {
        "method": "GET",
        "requestUri": "/v2/domains/{domainIdentifier}/listings/{identifier}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "identifier"
        ],
        "members": {
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "identifier": {
            "location": "uri",
            "locationName": "identifier"
          },
          "listingRevision": {
            "location": "querystring",
            "locationName": "listingRevision"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "domainId",
          "id",
          "listingRevision"
        ],
        "members": {
          "createdAt": {
            "type": "timestamp"
          },
          "createdBy": {},
          "description": {
            "shape": "Sq"
          },
          "domainId": {},
          "id": {},
          "item": {
            "type": "structure",
            "members": {
              "assetListing": {
                "type": "structure",
                "members": {
                  "assetId": {},
                  "assetRevision": {},
                  "assetType": {},
                  "createdAt": {
                    "type": "timestamp"
                  },
                  "forms": {},
                  "glossaryTerms": {
                    "shape": "Sw"
                  },
                  "latestTimeSeriesDataPointForms": {
                    "shape": "S20"
                  },
                  "owningProjectId": {}
                }
              }
            },
            "union": true
          },
          "listingRevision": {},
          "name": {},
          "status": {},
          "updatedAt": {
            "type": "timestamp"
          },
          "updatedBy": {}
        }
      }
    },
    "GetMetadataGenerationRun": {
      "http": {
        "method": "GET",
        "requestUri": "/v2/domains/{domainIdentifier}/metadata-generation-runs/{identifier}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "identifier"
        ],
        "members": {
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "identifier": {
            "location": "uri",
            "locationName": "identifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "domainId",
          "id",
          "owningProjectId"
        ],
        "members": {
          "createdAt": {
            "type": "timestamp"
          },
          "createdBy": {},
          "domainId": {},
          "id": {},
          "owningProjectId": {},
          "status": {},
          "target": {
            "shape": "Sb0"
          },
          "type": {}
        }
      }
    },
    "GetProject": {
      "http": {
        "method": "GET",
        "requestUri": "/v2/domains/{domainIdentifier}/projects/{identifier}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "identifier"
        ],
        "members": {
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "identifier": {
            "location": "uri",
            "locationName": "identifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "createdBy",
          "domainId",
          "id",
          "name"
        ],
        "members": {
          "createdAt": {
            "shape": "S4v"
          },
          "createdBy": {},
          "description": {
            "shape": "Sq"
          },
          "domainId": {},
          "failureReasons": {
            "shape": "S6n"
          },
          "glossaryTerms": {
            "shape": "S1q"
          },
          "id": {},
          "lastUpdatedAt": {
            "shape": "S4v"
          },
          "name": {
            "shape": "S15"
          },
          "projectStatus": {}
        }
      }
    },
    "GetSubscription": {
      "http": {
        "method": "GET",
        "requestUri": "/v2/domains/{domainIdentifier}/subscriptions/{identifier}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "identifier"
        ],
        "members": {
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "identifier": {
            "location": "uri",
            "locationName": "identifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "createdAt",
          "createdBy",
          "domainId",
          "id",
          "status",
          "subscribedListing",
          "subscribedPrincipal",
          "updatedAt"
        ],
        "members": {
          "createdAt": {
            "type": "timestamp"
          },
          "createdBy": {},
          "domainId": {},
          "id": {},
          "retainPermissions": {
            "type": "boolean"
          },
          "status": {},
          "subscribedListing": {
            "shape": "Sp"
          },
          "subscribedPrincipal": {
            "shape": "S13"
          },
          "subscriptionRequestId": {},
          "updatedAt": {
            "type": "timestamp"
          },
          "updatedBy": {}
        }
      }
    },
    "GetSubscriptionGrant": {
      "http": {
        "method": "GET",
        "requestUri": "/v2/domains/{domainIdentifier}/subscription-grants/{identifier}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "identifier"
        ],
        "members": {
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "identifier": {
            "location": "uri",
            "locationName": "identifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "createdAt",
          "createdBy",
          "domainId",
          "grantedEntity",
          "id",
          "status",
          "subscriptionTargetId",
          "updatedAt"
        ],
        "members": {
          "assets": {
            "shape": "S71"
          },
          "createdAt": {
            "type": "timestamp"
          },
          "createdBy": {},
          "domainId": {},
          "grantedEntity": {
            "shape": "S75"
          },
          "id": {},
          "status": {},
          "subscriptionId": {},
          "subscriptionTargetId": {},
          "updatedAt": {
            "type": "timestamp"
          },
          "updatedBy": {}
        }
      }
    },
    "GetSubscriptionRequestDetails": {
      "http": {
        "method": "GET",
        "requestUri": "/v2/domains/{domainIdentifier}/subscription-requests/{identifier}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "identifier"
        ],
        "members": {
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "identifier": {
            "location": "uri",
            "locationName": "identifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "createdAt",
          "createdBy",
          "domainId",
          "id",
          "requestReason",
          "status",
          "subscribedListings",
          "subscribedPrincipals",
          "updatedAt"
        ],
        "members": {
          "createdAt": {
            "type": "timestamp"
          },
          "createdBy": {},
          "decisionComment": {
            "shape": "Sh"
          },
          "domainId": {},
          "id": {},
          "requestReason": {
            "shape": "Sm"
          },
          "reviewerId": {},
          "status": {},
          "subscribedListings": {
            "type": "list",
            "member": {
              "shape": "Sp"
            }
          },
          "subscribedPrincipals": {
            "type": "list",
            "member": {
              "shape": "S13"
            }
          },
          "updatedAt": {
            "type": "timestamp"
          },
          "updatedBy": {}
        }
      }
    },
    "GetSubscriptionTarget": {
      "http": {
        "method": "GET",
        "requestUri": "/v2/domains/{domainIdentifier}/environments/{environmentIdentifier}/subscription-targets/{identifier}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "environmentIdentifier",
          "identifier"
        ],
        "members": {
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "environmentIdentifier": {
            "location": "uri",
            "locationName": "environmentIdentifier"
          },
          "identifier": {
            "location": "uri",
            "locationName": "identifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "applicableAssetTypes",
          "authorizedPrincipals",
          "createdAt",
          "createdBy",
          "domainId",
          "environmentId",
          "id",
          "manageAccessRole",
          "name",
          "projectId",
          "provider",
          "subscriptionTargetConfig",
          "type"
        ],
        "members": {
          "applicableAssetTypes": {
            "shape": "S7j"
          },
          "authorizedPrincipals": {
            "shape": "S7k"
          },
          "createdAt": {
            "type": "timestamp"
          },
          "createdBy": {},
          "domainId": {},
          "environmentId": {},
          "id": {},
          "manageAccessRole": {},
          "name": {
            "shape": "S7m"
          },
          "projectId": {},
          "provider": {},
          "subscriptionTargetConfig": {
            "shape": "S7n"
          },
          "type": {},
          "updatedAt": {
            "type": "timestamp"
          },
          "updatedBy": {}
        }
      }
    },
    "GetTimeSeriesDataPoint": {
      "http": {
        "method": "GET",
        "requestUri": "/v2/domains/{domainIdentifier}/entities/{entityType}/{entityIdentifier}/time-series-data-points/{identifier}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "entityIdentifier",
          "entityType",
          "formName",
          "identifier"
        ],
        "members": {
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "entityIdentifier": {
            "location": "uri",
            "locationName": "entityIdentifier"
          },
          "entityType": {
            "location": "uri",
            "locationName": "entityType"
          },
          "formName": {
            "location": "querystring",
            "locationName": "formName"
          },
          "identifier": {
            "location": "uri",
            "locationName": "identifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "domainId": {},
          "entityId": {},
          "entityType": {},
          "form": {
            "shape": "Sbj"
          },
          "formName": {}
        }
      }
    },
    "GetUserProfile": {
      "http": {
        "method": "GET",
        "requestUri": "/v2/domains/{domainIdentifier}/user-profiles/{userIdentifier}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "userIdentifier"
        ],
        "members": {
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "type": {
            "location": "querystring",
            "locationName": "type"
          },
          "userIdentifier": {
            "location": "uri",
            "locationName": "userIdentifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "details": {
            "shape": "S7u"
          },
          "domainId": {},
          "id": {},
          "status": {},
          "type": {}
        }
      }
    },
    "ListAssetFilters": {
      "http": {
        "method": "GET",
        "requestUri": "/v2/domains/{domainIdentifier}/assets/{assetIdentifier}/filters",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "assetIdentifier",
          "domainIdentifier"
        ],
        "members": {
          "assetIdentifier": {
            "location": "uri",
            "locationName": "assetIdentifier"
          },
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "status": {
            "location": "querystring",
            "locationName": "status"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "items"
        ],
        "members": {
          "items": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "assetId",
                "domainId",
                "id",
                "name"
              ],
              "members": {
                "assetId": {},
                "createdAt": {
                  "type": "timestamp"
                },
                "description": {
                  "shape": "Sq"
                },
                "domainId": {},
                "effectiveColumnNames": {
                  "shape": "S2b"
                },
                "effectiveRowFilter": {},
                "errorMessage": {},
                "id": {},
                "name": {
                  "shape": "S2t"
                },
                "status": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListAssetRevisions": {
      "http": {
        "method": "GET",
        "requestUri": "/v2/domains/{domainIdentifier}/assets/{identifier}/revisions",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "identifier"
        ],
        "members": {
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "identifier": {
            "location": "uri",
            "locationName": "identifier"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "items": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "createdAt": {
                  "type": "timestamp"
                },
                "createdBy": {},
                "domainId": {},
                "id": {},
                "revision": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListDataSourceRunActivities": {
      "http": {
        "method": "GET",
        "requestUri": "/v2/domains/{domainIdentifier}/data-source-runs/{identifier}/activities",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "identifier"
        ],
        "members": {
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "identifier": {
            "location": "uri",
            "locationName": "identifier"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "status": {
            "location": "querystring",
            "locationName": "status"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "items"
        ],
        "members": {
          "items": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "createdAt",
                "dataAssetStatus",
                "dataSourceRunId",
                "database",
                "projectId",
                "technicalName",
                "updatedAt"
              ],
              "members": {
                "createdAt": {
                  "shape": "S47"
                },
                "dataAssetId": {},
                "dataAssetStatus": {},
                "dataSourceRunId": {},
                "database": {
                  "shape": "S3r"
                },
                "errorMessage": {
                  "shape": "S48"
                },
                "projectId": {},
                "technicalDescription": {
                  "shape": "Sq"
                },
                "technicalName": {
                  "shape": "S3r"
                },
                "updatedAt": {
                  "shape": "S47"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListDataSourceRuns": {
      "http": {
        "method": "GET",
        "requestUri": "/v2/domains/{domainIdentifier}/data-sources/{dataSourceIdentifier}/runs",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "dataSourceIdentifier",
          "domainIdentifier"
        ],
        "members": {
          "dataSourceIdentifier": {
            "location": "uri",
            "locationName": "dataSourceIdentifier"
          },
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "status": {
            "location": "querystring",
            "locationName": "status"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "items"
        ],
        "members": {
          "items": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "createdAt",
                "dataSourceId",
                "id",
                "projectId",
                "status",
                "type",
                "updatedAt"
              ],
              "members": {
                "createdAt": {
                  "shape": "S47"
                },
                "dataSourceId": {},
                "errorMessage": {
                  "shape": "S48"
                },
                "id": {},
                "projectId": {},
                "runStatisticsForAssets": {
                  "shape": "S9l"
                },
                "startedAt": {
                  "shape": "S47"
                },
                "status": {},
                "stoppedAt": {
                  "shape": "S47"
                },
                "type": {},
                "updatedAt": {
                  "shape": "S47"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListDataSources": {
      "http": {
        "method": "GET",
        "requestUri": "/v2/domains/{domainIdentifier}/data-sources",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "projectIdentifier"
        ],
        "members": {
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "environmentIdentifier": {
            "location": "querystring",
            "locationName": "environmentIdentifier"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "name": {
            "shape": "S3r",
            "location": "querystring",
            "locationName": "name"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "projectIdentifier": {
            "location": "querystring",
            "locationName": "projectIdentifier"
          },
          "status": {
            "location": "querystring",
            "locationName": "status"
          },
          "type": {
            "location": "querystring",
            "locationName": "type"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "items"
        ],
        "members": {
          "items": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "dataSourceId",
                "domainId",
                "environmentId",
                "name",
                "status",
                "type"
              ],
              "members": {
                "createdAt": {
                  "shape": "S47"
                },
                "dataSourceId": {},
                "domainId": {},
                "enableSetting": {},
                "environmentId": {},
                "lastRunAssetCount": {
                  "type": "integer"
                },
                "lastRunAt": {
                  "shape": "S47"
                },
                "lastRunErrorMessage": {
                  "shape": "S48"
                },
                "lastRunStatus": {},
                "name": {
                  "shape": "S3r"
                },
                "schedule": {
                  "shape": "S3t"
                },
                "status": {},
                "type": {},
                "updatedAt": {
                  "shape": "S47"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListDomains": {
      "http": {
        "method": "GET",
        "requestUri": "/v2/domains",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "status": {
            "location": "querystring",
            "locationName": "status"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "items"
        ],
        "members": {
          "items": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "arn",
                "createdAt",
                "id",
                "managedAccountId",
                "name",
                "status"
              ],
              "members": {
                "arn": {},
                "createdAt": {
                  "type": "timestamp"
                },
                "description": {
                  "type": "string",
                  "sensitive": true
                },
                "id": {},
                "lastUpdatedAt": {
                  "type": "timestamp"
                },
                "managedAccountId": {},
                "name": {
                  "type": "string",
                  "sensitive": true
                },
                "portalUrl": {},
                "status": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListEnvironmentActions": {
      "http": {
        "method": "GET",
        "requestUri": "/v2/domains/{domainIdentifier}/environments/{environmentIdentifier}/actions",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "environmentIdentifier"
        ],
        "members": {
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "environmentIdentifier": {
            "location": "uri",
            "locationName": "environmentIdentifier"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "items": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "domainId",
                "environmentId",
                "id",
                "name",
                "parameters"
              ],
              "members": {
                "description": {},
                "domainId": {},
                "environmentId": {},
                "id": {},
                "name": {},
                "parameters": {
                  "shape": "S5l"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListEnvironmentBlueprintConfigurations": {
      "http": {
        "method": "GET",
        "requestUri": "/v2/domains/{domainIdentifier}/environment-blueprint-configurations",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier"
        ],
        "members": {
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "items": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "domainId",
                "environmentBlueprintId"
              ],
              "members": {
                "createdAt": {
                  "shape": "S4v"
                },
                "domainId": {},
                "enabledRegions": {
                  "shape": "S9y"
                },
                "environmentBlueprintId": {},
                "manageAccessRoleArn": {},
                "provisioningConfigurations": {
                  "shape": "Sa0"
                },
                "provisioningRoleArn": {},
                "regionalParameters": {
                  "shape": "Sa5"
                },
                "updatedAt": {
                  "shape": "S4v"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListEnvironmentBlueprints": {
      "http": {
        "method": "GET",
        "requestUri": "/v2/domains/{domainIdentifier}/environment-blueprints",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier"
        ],
        "members": {
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "managed": {
            "location": "querystring",
            "locationName": "managed",
            "type": "boolean"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "name": {
            "location": "querystring",
            "locationName": "name"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "items"
        ],
        "members": {
          "items": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "id",
                "name",
                "provider",
                "provisioningProperties"
              ],
              "members": {
                "createdAt": {
                  "shape": "S4v"
                },
                "description": {
                  "shape": "Sq"
                },
                "id": {},
                "name": {},
                "provider": {},
                "provisioningProperties": {
                  "shape": "S5e"
                },
                "updatedAt": {
                  "shape": "S4v"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListEnvironmentProfiles": {
      "http": {
        "method": "GET",
        "requestUri": "/v2/domains/{domainIdentifier}/environment-profiles",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier"
        ],
        "members": {
          "awsAccountId": {
            "location": "querystring",
            "locationName": "awsAccountId"
          },
          "awsAccountRegion": {
            "location": "querystring",
            "locationName": "awsAccountRegion"
          },
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "environmentBlueprintIdentifier": {
            "location": "querystring",
            "locationName": "environmentBlueprintIdentifier"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "name": {
            "shape": "S5q",
            "location": "querystring",
            "locationName": "name"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "projectIdentifier": {
            "location": "querystring",
            "locationName": "projectIdentifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "items"
        ],
        "members": {
          "items": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "createdBy",
                "domainId",
                "environmentBlueprintId",
                "id",
                "name"
              ],
              "members": {
                "awsAccountId": {},
                "awsAccountRegion": {},
                "createdAt": {
                  "shape": "S4v"
                },
                "createdBy": {},
                "description": {
                  "shape": "Sq"
                },
                "domainId": {},
                "environmentBlueprintId": {},
                "id": {},
                "name": {
                  "shape": "S5q"
                },
                "projectId": {},
                "updatedAt": {
                  "shape": "S4v"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListEnvironments": {
      "http": {
        "method": "GET",
        "requestUri": "/v2/domains/{domainIdentifier}/environments",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "projectIdentifier"
        ],
        "members": {
          "awsAccountId": {
            "location": "querystring",
            "locationName": "awsAccountId"
          },
          "awsAccountRegion": {
            "location": "querystring",
            "locationName": "awsAccountRegion"
          },
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "environmentBlueprintIdentifier": {
            "location": "querystring",
            "locationName": "environmentBlueprintIdentifier"
          },
          "environmentProfileIdentifier": {
            "location": "querystring",
            "locationName": "environmentProfileIdentifier"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "name": {
            "location": "querystring",
            "locationName": "name"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "projectIdentifier": {
            "location": "querystring",
            "locationName": "projectIdentifier"
          },
          "provider": {
            "location": "querystring",
            "locationName": "provider"
          },
          "status": {
            "location": "querystring",
            "locationName": "status"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "items"
        ],
        "members": {
          "items": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "createdBy",
                "domainId",
                "name",
                "projectId",
                "provider"
              ],
              "members": {
                "awsAccountId": {},
                "awsAccountRegion": {},
                "createdAt": {
                  "shape": "S4v"
                },
                "createdBy": {},
                "description": {
                  "shape": "Sq"
                },
                "domainId": {},
                "environmentProfileId": {},
                "id": {},
                "name": {
                  "shape": "S5b"
                },
                "projectId": {},
                "provider": {},
                "status": {},
                "updatedAt": {
                  "shape": "S4v"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListLineageNodeHistory": {
      "http": {
        "method": "GET",
        "requestUri": "/v2/domains/{domainIdentifier}/lineage/nodes/{identifier}/history",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "identifier"
        ],
        "members": {
          "direction": {
            "location": "querystring",
            "locationName": "direction"
          },
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "eventTimestampGTE": {
            "location": "querystring",
            "locationName": "timestampGTE",
            "type": "timestamp"
          },
          "eventTimestampLTE": {
            "location": "querystring",
            "locationName": "timestampLTE",
            "type": "timestamp"
          },
          "identifier": {
            "location": "uri",
            "locationName": "identifier"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "sortOrder": {
            "location": "querystring",
            "locationName": "sortOrder"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "nodes": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "domainId",
                "id",
                "typeName"
              ],
              "members": {
                "createdAt": {
                  "type": "timestamp"
                },
                "createdBy": {},
                "description": {},
                "domainId": {},
                "eventTimestamp": {
                  "type": "timestamp"
                },
                "id": {},
                "name": {},
                "sourceIdentifier": {},
                "typeName": {},
                "typeRevision": {},
                "updatedAt": {
                  "type": "timestamp"
                },
                "updatedBy": {}
              }
            }
          }
        }
      }
    },
    "ListMetadataGenerationRuns": {
      "http": {
        "method": "GET",
        "requestUri": "/v2/domains/{domainIdentifier}/metadata-generation-runs",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier"
        ],
        "members": {
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "status": {
            "location": "querystring",
            "locationName": "status"
          },
          "type": {
            "location": "querystring",
            "locationName": "type"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "items": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "domainId",
                "id",
                "owningProjectId"
              ],
              "members": {
                "createdAt": {
                  "type": "timestamp"
                },
                "createdBy": {},
                "domainId": {},
                "id": {},
                "owningProjectId": {},
                "status": {},
                "target": {
                  "shape": "Sb0"
                },
                "type": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListNotifications": {
      "http": {
        "method": "GET",
        "requestUri": "/v2/domains/{domainIdentifier}/notifications",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "type"
        ],
        "members": {
          "afterTimestamp": {
            "location": "querystring",
            "locationName": "afterTimestamp",
            "type": "timestamp"
          },
          "beforeTimestamp": {
            "location": "querystring",
            "locationName": "beforeTimestamp",
            "type": "timestamp"
          },
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "subjects": {
            "location": "querystring",
            "locationName": "subjects",
            "type": "list",
            "member": {}
          },
          "taskStatus": {
            "location": "querystring",
            "locationName": "taskStatus"
          },
          "type": {
            "location": "querystring",
            "locationName": "type"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "notifications": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "actionLink",
                "creationTimestamp",
                "domainIdentifier",
                "identifier",
                "lastUpdatedTimestamp",
                "message",
                "title",
                "topic",
                "type"
              ],
              "members": {
                "actionLink": {
                  "type": "string",
                  "sensitive": true
                },
                "creationTimestamp": {
                  "type": "timestamp"
                },
                "domainIdentifier": {},
                "identifier": {},
                "lastUpdatedTimestamp": {
                  "type": "timestamp"
                },
                "message": {
                  "type": "string",
                  "sensitive": true
                },
                "metadata": {
                  "type": "map",
                  "key": {},
                  "value": {}
                },
                "status": {},
                "title": {
                  "type": "string",
                  "sensitive": true
                },
                "topic": {
                  "type": "structure",
                  "required": [
                    "resource",
                    "role",
                    "subject"
                  ],
                  "members": {
                    "resource": {
                      "type": "structure",
                      "required": [
                        "id",
                        "type"
                      ],
                      "members": {
                        "id": {},
                        "name": {},
                        "type": {}
                      }
                    },
                    "role": {},
                    "subject": {}
                  }
                },
                "type": {}
              }
            }
          }
        }
      }
    },
    "ListProjectMemberships": {
      "http": {
        "method": "GET",
        "requestUri": "/v2/domains/{domainIdentifier}/projects/{projectIdentifier}/memberships",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "projectIdentifier"
        ],
        "members": {
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "projectIdentifier": {
            "location": "uri",
            "locationName": "projectIdentifier"
          },
          "sortBy": {
            "location": "querystring",
            "locationName": "sortBy"
          },
          "sortOrder": {
            "location": "querystring",
            "locationName": "sortOrder"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "members"
        ],
        "members": {
          "members": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "designation",
                "memberDetails"
              ],
              "members": {
                "designation": {},
                "memberDetails": {
                  "type": "structure",
                  "members": {
                    "group": {
                      "type": "structure",
                      "required": [
                        "groupId"
                      ],
                      "members": {
                        "groupId": {}
                      }
                    },
                    "user": {
                      "type": "structure",
                      "required": [
                        "userId"
                      ],
                      "members": {
                        "userId": {}
                      }
                    }
                  },
                  "union": true
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListProjects": {
      "http": {
        "method": "GET",
        "requestUri": "/v2/domains/{domainIdentifier}/projects",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier"
        ],
        "members": {
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "groupIdentifier": {
            "location": "querystring",
            "locationName": "groupIdentifier"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "name": {
            "shape": "S15",
            "location": "querystring",
            "locationName": "name"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "userIdentifier": {
            "location": "querystring",
            "locationName": "userIdentifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "items": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "createdBy",
                "domainId",
                "id",
                "name"
              ],
              "members": {
                "createdAt": {
                  "shape": "S4v"
                },
                "createdBy": {},
                "description": {
                  "shape": "Sq"
                },
                "domainId": {},
                "failureReasons": {
                  "shape": "S6n"
                },
                "id": {},
                "name": {
                  "shape": "S15"
                },
                "projectStatus": {},
                "updatedAt": {
                  "shape": "S4v"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListSubscriptionGrants": {
      "http": {
        "method": "GET",
        "requestUri": "/v2/domains/{domainIdentifier}/subscription-grants",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier"
        ],
        "members": {
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "environmentId": {
            "location": "querystring",
            "locationName": "environmentId"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "sortBy": {
            "location": "querystring",
            "locationName": "sortBy"
          },
          "sortOrder": {
            "location": "querystring",
            "locationName": "sortOrder"
          },
          "subscribedListingId": {
            "location": "querystring",
            "locationName": "subscribedListingId"
          },
          "subscriptionId": {
            "location": "querystring",
            "locationName": "subscriptionId"
          },
          "subscriptionTargetId": {
            "location": "querystring",
            "locationName": "subscriptionTargetId"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "items"
        ],
        "members": {
          "items": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "createdAt",
                "createdBy",
                "domainId",
                "grantedEntity",
                "id",
                "status",
                "subscriptionTargetId",
                "updatedAt"
              ],
              "members": {
                "assets": {
                  "shape": "S71"
                },
                "createdAt": {
                  "type": "timestamp"
                },
                "createdBy": {},
                "domainId": {},
                "grantedEntity": {
                  "shape": "S75"
                },
                "id": {},
                "status": {},
                "subscriptionId": {},
                "subscriptionTargetId": {},
                "updatedAt": {
                  "type": "timestamp"
                },
                "updatedBy": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListSubscriptionRequests": {
      "http": {
        "method": "GET",
        "requestUri": "/v2/domains/{domainIdentifier}/subscription-requests",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier"
        ],
        "members": {
          "approverProjectId": {
            "location": "querystring",
            "locationName": "approverProjectId"
          },
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "owningProjectId": {
            "location": "querystring",
            "locationName": "owningProjectId"
          },
          "sortBy": {
            "location": "querystring",
            "locationName": "sortBy"
          },
          "sortOrder": {
            "location": "querystring",
            "locationName": "sortOrder"
          },
          "status": {
            "location": "querystring",
            "locationName": "status"
          },
          "subscribedListingId": {
            "location": "querystring",
            "locationName": "subscribedListingId"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "items"
        ],
        "members": {
          "items": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "createdAt",
                "createdBy",
                "domainId",
                "id",
                "requestReason",
                "status",
                "subscribedListings",
                "subscribedPrincipals",
                "updatedAt"
              ],
              "members": {
                "createdAt": {
                  "type": "timestamp"
                },
                "createdBy": {},
                "decisionComment": {
                  "shape": "Sh"
                },
                "domainId": {},
                "id": {},
                "requestReason": {
                  "shape": "Sm"
                },
                "reviewerId": {},
                "status": {},
                "subscribedListings": {
                  "type": "list",
                  "member": {
                    "shape": "Sp"
                  }
                },
                "subscribedPrincipals": {
                  "type": "list",
                  "member": {
                    "shape": "S13"
                  }
                },
                "updatedAt": {
                  "type": "timestamp"
                },
                "updatedBy": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListSubscriptionTargets": {
      "http": {
        "method": "GET",
        "requestUri": "/v2/domains/{domainIdentifier}/environments/{environmentIdentifier}/subscription-targets",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "environmentIdentifier"
        ],
        "members": {
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "environmentIdentifier": {
            "location": "uri",
            "locationName": "environmentIdentifier"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "sortBy": {
            "location": "querystring",
            "locationName": "sortBy"
          },
          "sortOrder": {
            "location": "querystring",
            "locationName": "sortOrder"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "items"
        ],
        "members": {
          "items": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "applicableAssetTypes",
                "authorizedPrincipals",
                "createdAt",
                "createdBy",
                "domainId",
                "environmentId",
                "id",
                "manageAccessRole",
                "name",
                "projectId",
                "provider",
                "subscriptionTargetConfig",
                "type"
              ],
              "members": {
                "applicableAssetTypes": {
                  "shape": "S7j"
                },
                "authorizedPrincipals": {
                  "shape": "S7k"
                },
                "createdAt": {
                  "type": "timestamp"
                },
                "createdBy": {},
                "domainId": {},
                "environmentId": {},
                "id": {},
                "manageAccessRole": {},
                "name": {
                  "shape": "S7m"
                },
                "projectId": {},
                "provider": {},
                "subscriptionTargetConfig": {
                  "shape": "S7n"
                },
                "type": {},
                "updatedAt": {
                  "type": "timestamp"
                },
                "updatedBy": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListSubscriptions": {
      "http": {
        "method": "GET",
        "requestUri": "/v2/domains/{domainIdentifier}/subscriptions",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier"
        ],
        "members": {
          "approverProjectId": {
            "location": "querystring",
            "locationName": "approverProjectId"
          },
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "owningProjectId": {
            "location": "querystring",
            "locationName": "owningProjectId"
          },
          "sortBy": {
            "location": "querystring",
            "locationName": "sortBy"
          },
          "sortOrder": {
            "location": "querystring",
            "locationName": "sortOrder"
          },
          "status": {
            "location": "querystring",
            "locationName": "status"
          },
          "subscribedListingId": {
            "location": "querystring",
            "locationName": "subscribedListingId"
          },
          "subscriptionRequestIdentifier": {
            "location": "querystring",
            "locationName": "subscriptionRequestIdentifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "items"
        ],
        "members": {
          "items": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "createdAt",
                "createdBy",
                "domainId",
                "id",
                "status",
                "subscribedListing",
                "subscribedPrincipal",
                "updatedAt"
              ],
              "members": {
                "createdAt": {
                  "type": "timestamp"
                },
                "createdBy": {},
                "domainId": {},
                "id": {},
                "retainPermissions": {
                  "type": "boolean"
                },
                "status": {},
                "subscribedListing": {
                  "shape": "Sp"
                },
                "subscribedPrincipal": {
                  "shape": "S13"
                },
                "subscriptionRequestId": {},
                "updatedAt": {
                  "type": "timestamp"
                },
                "updatedBy": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tags": {
            "shape": "S4j"
          }
        }
      }
    },
    "ListTimeSeriesDataPoints": {
      "http": {
        "method": "GET",
        "requestUri": "/v2/domains/{domainIdentifier}/entities/{entityType}/{entityIdentifier}/time-series-data-points",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "entityIdentifier",
          "entityType",
          "formName"
        ],
        "members": {
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "endedAt": {
            "location": "querystring",
            "locationName": "endedAt",
            "type": "timestamp"
          },
          "entityIdentifier": {
            "location": "uri",
            "locationName": "entityIdentifier"
          },
          "entityType": {
            "location": "uri",
            "locationName": "entityType"
          },
          "formName": {
            "location": "querystring",
            "locationName": "formName"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "startedAt": {
            "location": "querystring",
            "locationName": "startedAt",
            "type": "timestamp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "items": {
            "shape": "S20"
          },
          "nextToken": {}
        }
      }
    },
    "PostLineageEvent": {
      "http": {
        "requestUri": "/v2/domains/{domainIdentifier}/lineage/events",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "event"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true,
            "location": "querystring",
            "locationName": "clientToken"
          },
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "event": {
            "type": "blob",
            "sensitive": true
          }
        },
        "payload": "event"
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "PostTimeSeriesDataPoints": {
      "http": {
        "requestUri": "/v2/domains/{domainIdentifier}/entities/{entityType}/{entityIdentifier}/time-series-data-points",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "entityIdentifier",
          "entityType",
          "forms"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "entityIdentifier": {
            "location": "uri",
            "locationName": "entityIdentifier"
          },
          "entityType": {
            "location": "uri",
            "locationName": "entityType"
          },
          "forms": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "formName",
                "timestamp",
                "typeIdentifier"
              ],
              "members": {
                "content": {},
                "formName": {},
                "timestamp": {
                  "type": "timestamp"
                },
                "typeIdentifier": {},
                "typeRevision": {}
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "domainId": {},
          "entityId": {},
          "entityType": {},
          "forms": {
            "type": "list",
            "member": {
              "shape": "Sbj"
            }
          }
        }
      },
      "idempotent": true
    },
    "PutEnvironmentBlueprintConfiguration": {
      "http": {
        "method": "PUT",
        "requestUri": "/v2/domains/{domainIdentifier}/environment-blueprint-configurations/{environmentBlueprintIdentifier}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "enabledRegions",
          "environmentBlueprintIdentifier"
        ],
        "members": {
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "enabledRegions": {
            "shape": "S9y"
          },
          "environmentBlueprintIdentifier": {
            "location": "uri",
            "locationName": "environmentBlueprintIdentifier"
          },
          "manageAccessRoleArn": {},
          "provisioningConfigurations": {
            "shape": "Sa0"
          },
          "provisioningRoleArn": {},
          "regionalParameters": {
            "shape": "Sa5"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "domainId",
          "environmentBlueprintId"
        ],
        "members": {
          "createdAt": {
            "shape": "S4v"
          },
          "domainId": {},
          "enabledRegions": {
            "shape": "S9y"
          },
          "environmentBlueprintId": {},
          "manageAccessRoleArn": {},
          "provisioningConfigurations": {
            "shape": "Sa0"
          },
          "provisioningRoleArn": {},
          "regionalParameters": {
            "shape": "Sa5"
          },
          "updatedAt": {
            "shape": "S4v"
          }
        }
      },
      "idempotent": true
    },
    "RejectPredictions": {
      "http": {
        "method": "PUT",
        "requestUri": "/v2/domains/{domainIdentifier}/assets/{identifier}/reject-predictions",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "identifier"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "identifier": {
            "location": "uri",
            "locationName": "identifier"
          },
          "rejectChoices": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "predictionTarget"
              ],
              "members": {
                "predictionChoices": {
                  "type": "list",
                  "member": {
                    "type": "integer"
                  }
                },
                "predictionTarget": {}
              }
            }
          },
          "rejectRule": {
            "type": "structure",
            "members": {
              "rule": {},
              "threshold": {
                "type": "float"
              }
            }
          },
          "revision": {
            "location": "querystring",
            "locationName": "revision"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "assetId",
          "assetRevision",
          "domainId"
        ],
        "members": {
          "assetId": {},
          "assetRevision": {},
          "domainId": {}
        }
      },
      "idempotent": true
    },
    "RejectSubscriptionRequest": {
      "http": {
        "method": "PUT",
        "requestUri": "/v2/domains/{domainIdentifier}/subscription-requests/{identifier}/reject",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "identifier"
        ],
        "members": {
          "decisionComment": {
            "shape": "Sh"
          },
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "identifier": {
            "location": "uri",
            "locationName": "identifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "createdAt",
          "createdBy",
          "domainId",
          "id",
          "requestReason",
          "status",
          "subscribedListings",
          "subscribedPrincipals",
          "updatedAt"
        ],
        "members": {
          "createdAt": {
            "type": "timestamp"
          },
          "createdBy": {},
          "decisionComment": {
            "shape": "Sh"
          },
          "domainId": {},
          "id": {},
          "requestReason": {
            "shape": "Sm"
          },
          "reviewerId": {},
          "status": {},
          "subscribedListings": {
            "type": "list",
            "member": {
              "shape": "Sp"
            }
          },
          "subscribedPrincipals": {
            "type": "list",
            "member": {
              "shape": "S13"
            }
          },
          "updatedAt": {
            "type": "timestamp"
          },
          "updatedBy": {}
        }
      },
      "idempotent": true
    },
    "RevokeSubscription": {
      "http": {
        "method": "PUT",
        "requestUri": "/v2/domains/{domainIdentifier}/subscriptions/{identifier}/revoke",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "identifier"
        ],
        "members": {
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "identifier": {
            "location": "uri",
            "locationName": "identifier"
          },
          "retainPermissions": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "createdAt",
          "createdBy",
          "domainId",
          "id",
          "status",
          "subscribedListing",
          "subscribedPrincipal",
          "updatedAt"
        ],
        "members": {
          "createdAt": {
            "type": "timestamp"
          },
          "createdBy": {},
          "domainId": {},
          "id": {},
          "retainPermissions": {
            "type": "boolean"
          },
          "status": {},
          "subscribedListing": {
            "shape": "Sp"
          },
          "subscribedPrincipal": {
            "shape": "S13"
          },
          "subscriptionRequestId": {},
          "updatedAt": {
            "type": "timestamp"
          },
          "updatedBy": {}
        }
      },
      "idempotent": true
    },
    "Search": {
      "http": {
        "requestUri": "/v2/domains/{domainIdentifier}/search",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "searchScope"
        ],
        "members": {
          "additionalAttributes": {
            "shape": "Sff"
          },
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "filters": {
            "shape": "Sfh"
          },
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {},
          "owningProjectIdentifier": {},
          "searchIn": {
            "shape": "Sfm"
          },
          "searchScope": {},
          "searchText": {},
          "sort": {
            "shape": "Sfq"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "items": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "assetItem": {
                  "type": "structure",
                  "required": [
                    "domainId",
                    "identifier",
                    "name",
                    "owningProjectId",
                    "typeIdentifier",
                    "typeRevision"
                  ],
                  "members": {
                    "additionalAttributes": {
                      "type": "structure",
                      "members": {
                        "formsOutput": {
                          "shape": "S1x"
                        },
                        "latestTimeSeriesDataPointFormsOutput": {
                          "shape": "S20"
                        },
                        "readOnlyFormsOutput": {
                          "shape": "S1x"
                        }
                      }
                    },
                    "createdAt": {
                      "type": "timestamp"
                    },
                    "createdBy": {},
                    "description": {
                      "shape": "Sq"
                    },
                    "domainId": {},
                    "externalIdentifier": {
                      "shape": "S1k"
                    },
                    "firstRevisionCreatedAt": {
                      "type": "timestamp"
                    },
                    "firstRevisionCreatedBy": {},
                    "glossaryTerms": {
                      "shape": "S1q"
                    },
                    "identifier": {},
                    "name": {
                      "shape": "S1s"
                    },
                    "owningProjectId": {},
                    "typeIdentifier": {},
                    "typeRevision": {}
                  }
                },
                "glossaryItem": {
                  "type": "structure",
                  "required": [
                    "domainId",
                    "id",
                    "name",
                    "owningProjectId",
                    "status"
                  ],
                  "members": {
                    "createdAt": {
                      "type": "timestamp"
                    },
                    "createdBy": {},
                    "description": {
                      "shape": "S5y"
                    },
                    "domainId": {},
                    "id": {},
                    "name": {
                      "shape": "S5z"
                    },
                    "owningProjectId": {},
                    "status": {},
                    "updatedAt": {
                      "type": "timestamp"
                    },
                    "updatedBy": {}
                  }
                },
                "glossaryTermItem": {
                  "type": "structure",
                  "required": [
                    "domainId",
                    "glossaryId",
                    "id",
                    "name",
                    "status"
                  ],
                  "members": {
                    "createdAt": {
                      "type": "timestamp"
                    },
                    "createdBy": {},
                    "domainId": {},
                    "glossaryId": {},
                    "id": {},
                    "longDescription": {
                      "shape": "S64"
                    },
                    "name": {
                      "shape": "Sy"
                    },
                    "shortDescription": {
                      "shape": "Sz"
                    },
                    "status": {},
                    "termRelations": {
                      "shape": "S66"
                    },
                    "updatedAt": {
                      "type": "timestamp"
                    },
                    "updatedBy": {}
                  }
                }
              },
              "union": true
            }
          },
          "nextToken": {},
          "totalMatchCount": {
            "type": "integer"
          }
        }
      }
    },
    "SearchGroupProfiles": {
      "http": {
        "requestUri": "/v2/domains/{domainIdentifier}/search-group-profiles",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "groupType"
        ],
        "members": {
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "groupType": {},
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {},
          "searchText": {
            "type": "string",
            "sensitive": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "items": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "domainId": {},
                "groupName": {
                  "shape": "S6d"
                },
                "id": {},
                "status": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "SearchListings": {
      "http": {
        "requestUri": "/v2/domains/{domainIdentifier}/listings/search",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier"
        ],
        "members": {
          "additionalAttributes": {
            "shape": "Sff"
          },
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "filters": {
            "shape": "Sfh"
          },
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {},
          "searchIn": {
            "shape": "Sfm"
          },
          "searchText": {},
          "sort": {
            "shape": "Sfq"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "items": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "assetListing": {
                  "type": "structure",
                  "members": {
                    "additionalAttributes": {
                      "type": "structure",
                      "members": {
                        "forms": {},
                        "latestTimeSeriesDataPointForms": {
                          "shape": "S20"
                        }
                      }
                    },
                    "createdAt": {
                      "type": "timestamp"
                    },
                    "description": {
                      "shape": "Sq"
                    },
                    "entityId": {},
                    "entityRevision": {},
                    "entityType": {},
                    "glossaryTerms": {
                      "shape": "Sw"
                    },
                    "listingCreatedBy": {},
                    "listingId": {},
                    "listingRevision": {},
                    "listingUpdatedBy": {},
                    "name": {
                      "shape": "S1s"
                    },
                    "owningProjectId": {}
                  }
                }
              },
              "union": true
            }
          },
          "nextToken": {},
          "totalMatchCount": {
            "type": "integer"
          }
        }
      }
    },
    "SearchTypes": {
      "http": {
        "requestUri": "/v2/domains/{domainIdentifier}/types-search",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "managed",
          "searchScope"
        ],
        "members": {
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "filters": {
            "shape": "Sfh"
          },
          "managed": {
            "type": "boolean"
          },
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {},
          "searchIn": {
            "shape": "Sfm"
          },
          "searchScope": {},
          "searchText": {},
          "sort": {
            "shape": "Sfq"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "items": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "assetTypeItem": {
                  "type": "structure",
                  "required": [
                    "domainId",
                    "formsOutput",
                    "name",
                    "owningProjectId",
                    "revision"
                  ],
                  "members": {
                    "createdAt": {
                      "type": "timestamp"
                    },
                    "createdBy": {},
                    "description": {
                      "shape": "Sq"
                    },
                    "domainId": {},
                    "formsOutput": {
                      "shape": "S33"
                    },
                    "name": {},
                    "originDomainId": {},
                    "originProjectId": {},
                    "owningProjectId": {},
                    "revision": {},
                    "updatedAt": {
                      "type": "timestamp"
                    },
                    "updatedBy": {}
                  }
                },
                "formTypeItem": {
                  "type": "structure",
                  "required": [
                    "domainId",
                    "name",
                    "revision"
                  ],
                  "members": {
                    "createdAt": {
                      "type": "timestamp"
                    },
                    "createdBy": {},
                    "description": {
                      "shape": "Sq"
                    },
                    "domainId": {},
                    "imports": {
                      "shape": "Sad"
                    },
                    "model": {
                      "shape": "S5t"
                    },
                    "name": {
                      "shape": "S1z"
                    },
                    "originDomainId": {},
                    "originProjectId": {},
                    "owningProjectId": {},
                    "revision": {},
                    "status": {}
                  }
                },
                "lineageNodeTypeItem": {
                  "type": "structure",
                  "required": [
                    "domainId",
                    "formsOutput",
                    "revision"
                  ],
                  "members": {
                    "createdAt": {
                      "type": "timestamp"
                    },
                    "createdBy": {},
                    "description": {},
                    "domainId": {},
                    "formsOutput": {
                      "shape": "S33"
                    },
                    "name": {},
                    "revision": {},
                    "updatedAt": {
                      "type": "timestamp"
                    },
                    "updatedBy": {}
                  }
                }
              },
              "union": true
            }
          },
          "nextToken": {},
          "totalMatchCount": {
            "type": "integer"
          }
        }
      }
    },
    "SearchUserProfiles": {
      "http": {
        "requestUri": "/v2/domains/{domainIdentifier}/search-user-profiles",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "userType"
        ],
        "members": {
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {},
          "searchText": {
            "type": "string",
            "sensitive": true
          },
          "userType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "items": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "details": {
                  "shape": "S7u"
                },
                "domainId": {},
                "id": {},
                "status": {},
                "type": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "StartDataSourceRun": {
      "http": {
        "requestUri": "/v2/domains/{domainIdentifier}/data-sources/{dataSourceIdentifier}/runs",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "dataSourceIdentifier",
          "domainIdentifier"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "dataSourceIdentifier": {
            "location": "uri",
            "locationName": "dataSourceIdentifier"
          },
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "createdAt",
          "dataSourceId",
          "domainId",
          "id",
          "projectId",
          "status",
          "type",
          "updatedAt"
        ],
        "members": {
          "createdAt": {
            "shape": "S47"
          },
          "dataSourceConfigurationSnapshot": {},
          "dataSourceId": {},
          "domainId": {},
          "errorMessage": {
            "shape": "S48"
          },
          "id": {},
          "projectId": {},
          "runStatisticsForAssets": {
            "shape": "S9l"
          },
          "startedAt": {
            "shape": "S47"
          },
          "status": {},
          "stoppedAt": {
            "shape": "S47"
          },
          "type": {},
          "updatedAt": {
            "shape": "S47"
          }
        }
      },
      "idempotent": true
    },
    "StartMetadataGenerationRun": {
      "http": {
        "requestUri": "/v2/domains/{domainIdentifier}/metadata-generation-runs",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "owningProjectIdentifier",
          "target",
          "type"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "owningProjectIdentifier": {},
          "target": {
            "shape": "Sb0"
          },
          "type": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "domainId",
          "id"
        ],
        "members": {
          "createdAt": {
            "type": "timestamp"
          },
          "createdBy": {},
          "domainId": {},
          "id": {},
          "owningProjectId": {},
          "status": {},
          "type": {}
        }
      },
      "idempotent": true
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tags"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tags": {
            "shape": "S4j"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tagKeys"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tagKeys": {
            "location": "querystring",
            "locationName": "tagKeys",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "UpdateAssetFilter": {
      "http": {
        "method": "PATCH",
        "requestUri": "/v2/domains/{domainIdentifier}/assets/{assetIdentifier}/filters/{identifier}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "assetIdentifier",
          "domainIdentifier",
          "identifier"
        ],
        "members": {
          "assetIdentifier": {
            "location": "uri",
            "locationName": "assetIdentifier"
          },
          "configuration": {
            "shape": "S29"
          },
          "description": {
            "shape": "Sq"
          },
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "identifier": {
            "location": "uri",
            "locationName": "identifier"
          },
          "name": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "assetId",
          "configuration",
          "domainId",
          "id",
          "name"
        ],
        "members": {
          "assetId": {},
          "configuration": {
            "shape": "S29"
          },
          "createdAt": {
            "type": "timestamp"
          },
          "description": {
            "shape": "Sq"
          },
          "domainId": {},
          "effectiveColumnNames": {
            "shape": "S2b"
          },
          "effectiveRowFilter": {},
          "errorMessage": {},
          "id": {},
          "name": {
            "shape": "S2t"
          },
          "status": {}
        }
      },
      "idempotent": true
    },
    "UpdateDataSource": {
      "http": {
        "method": "PATCH",
        "requestUri": "/v2/domains/{domainIdentifier}/data-sources/{identifier}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "identifier"
        ],
        "members": {
          "assetFormsInput": {
            "shape": "S1l"
          },
          "configuration": {
            "shape": "S36"
          },
          "description": {
            "shape": "Sq"
          },
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "enableSetting": {},
          "identifier": {
            "location": "uri",
            "locationName": "identifier"
          },
          "name": {
            "shape": "S3r"
          },
          "publishOnImport": {
            "type": "boolean"
          },
          "recommendation": {
            "shape": "S3s"
          },
          "retainPermissionsOnRevokeFailure": {
            "type": "boolean"
          },
          "schedule": {
            "shape": "S3t"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "domainId",
          "environmentId",
          "id",
          "name",
          "projectId"
        ],
        "members": {
          "assetFormsOutput": {
            "shape": "S1x"
          },
          "configuration": {
            "shape": "S3y"
          },
          "createdAt": {
            "shape": "S47"
          },
          "description": {
            "shape": "Sq"
          },
          "domainId": {},
          "enableSetting": {},
          "environmentId": {},
          "errorMessage": {
            "shape": "S48"
          },
          "id": {},
          "lastRunAt": {
            "shape": "S47"
          },
          "lastRunErrorMessage": {
            "shape": "S48"
          },
          "lastRunStatus": {},
          "name": {
            "shape": "S3r"
          },
          "projectId": {},
          "publishOnImport": {
            "type": "boolean"
          },
          "recommendation": {
            "shape": "S3s"
          },
          "retainPermissionsOnRevokeFailure": {
            "type": "boolean"
          },
          "schedule": {
            "shape": "S3t"
          },
          "selfGrantStatus": {
            "shape": "S8a"
          },
          "status": {},
          "type": {},
          "updatedAt": {
            "shape": "S47"
          }
        }
      },
      "idempotent": true
    },
    "UpdateDomain": {
      "http": {
        "method": "PUT",
        "requestUri": "/v2/domains/{identifier}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "identifier"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true,
            "location": "querystring",
            "locationName": "clientToken"
          },
          "description": {},
          "domainExecutionRole": {},
          "identifier": {
            "location": "uri",
            "locationName": "identifier"
          },
          "name": {},
          "singleSignOn": {
            "shape": "S4g"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "id"
        ],
        "members": {
          "description": {},
          "domainExecutionRole": {},
          "id": {},
          "lastUpdatedAt": {
            "type": "timestamp"
          },
          "name": {},
          "singleSignOn": {
            "shape": "S4g"
          }
        }
      },
      "idempotent": true
    },
    "UpdateEnvironment": {
      "http": {
        "method": "PATCH",
        "requestUri": "/v2/domains/{domainIdentifier}/environments/{identifier}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "identifier"
        ],
        "members": {
          "description": {},
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "glossaryTerms": {
            "shape": "S1q"
          },
          "identifier": {
            "location": "uri",
            "locationName": "identifier"
          },
          "name": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "createdBy",
          "domainId",
          "name",
          "projectId",
          "provider"
        ],
        "members": {
          "awsAccountId": {},
          "awsAccountRegion": {},
          "createdAt": {
            "shape": "S4v"
          },
          "createdBy": {},
          "deploymentProperties": {
            "shape": "S4w"
          },
          "description": {
            "shape": "Sq"
          },
          "domainId": {},
          "environmentActions": {
            "shape": "S4z"
          },
          "environmentBlueprintId": {},
          "environmentProfileId": {},
          "glossaryTerms": {
            "shape": "S1q"
          },
          "id": {},
          "lastDeployment": {
            "shape": "S55"
          },
          "name": {
            "shape": "S5b"
          },
          "projectId": {},
          "provider": {},
          "provisionedResources": {
            "shape": "S5c"
          },
          "provisioningProperties": {
            "shape": "S5e"
          },
          "status": {},
          "updatedAt": {
            "shape": "S4v"
          },
          "userParameters": {
            "shape": "S5h"
          }
        }
      }
    },
    "UpdateEnvironmentAction": {
      "http": {
        "method": "PATCH",
        "requestUri": "/v2/domains/{domainIdentifier}/environments/{environmentIdentifier}/actions/{identifier}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "environmentIdentifier",
          "identifier"
        ],
        "members": {
          "description": {},
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "environmentIdentifier": {
            "location": "uri",
            "locationName": "environmentIdentifier"
          },
          "identifier": {
            "location": "uri",
            "locationName": "identifier"
          },
          "name": {},
          "parameters": {
            "shape": "S5l"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "domainId",
          "environmentId",
          "id",
          "name",
          "parameters"
        ],
        "members": {
          "description": {},
          "domainId": {},
          "environmentId": {},
          "id": {},
          "name": {},
          "parameters": {
            "shape": "S5l"
          }
        }
      }
    },
    "UpdateEnvironmentProfile": {
      "http": {
        "method": "PATCH",
        "requestUri": "/v2/domains/{domainIdentifier}/environment-profiles/{identifier}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "identifier"
        ],
        "members": {
          "awsAccountId": {},
          "awsAccountRegion": {},
          "description": {},
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "identifier": {
            "location": "uri",
            "locationName": "identifier"
          },
          "name": {
            "shape": "S5q"
          },
          "userParameters": {
            "shape": "S4q"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "createdBy",
          "domainId",
          "environmentBlueprintId",
          "id",
          "name"
        ],
        "members": {
          "awsAccountId": {},
          "awsAccountRegion": {},
          "createdAt": {
            "shape": "S4v"
          },
          "createdBy": {},
          "description": {
            "shape": "Sq"
          },
          "domainId": {},
          "environmentBlueprintId": {},
          "id": {},
          "name": {
            "shape": "S5q"
          },
          "projectId": {},
          "updatedAt": {
            "shape": "S4v"
          },
          "userParameters": {
            "shape": "S5h"
          }
        }
      }
    },
    "UpdateGlossary": {
      "http": {
        "method": "PATCH",
        "requestUri": "/v2/domains/{domainIdentifier}/glossaries/{identifier}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "identifier"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "description": {
            "shape": "S5y"
          },
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "identifier": {
            "location": "uri",
            "locationName": "identifier"
          },
          "name": {
            "shape": "S5z"
          },
          "status": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "domainId",
          "id",
          "name",
          "owningProjectId"
        ],
        "members": {
          "description": {
            "shape": "S5y"
          },
          "domainId": {},
          "id": {},
          "name": {
            "shape": "S5z"
          },
          "owningProjectId": {},
          "status": {}
        }
      },
      "idempotent": true
    },
    "UpdateGlossaryTerm": {
      "http": {
        "method": "PATCH",
        "requestUri": "/v2/domains/{domainIdentifier}/glossary-terms/{identifier}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "identifier"
        ],
        "members": {
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "glossaryIdentifier": {},
          "identifier": {
            "location": "uri",
            "locationName": "identifier"
          },
          "longDescription": {
            "shape": "S64"
          },
          "name": {
            "shape": "Sy"
          },
          "shortDescription": {
            "shape": "Sz"
          },
          "status": {},
          "termRelations": {
            "shape": "S66"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "domainId",
          "glossaryId",
          "id",
          "name",
          "status"
        ],
        "members": {
          "domainId": {},
          "glossaryId": {},
          "id": {},
          "longDescription": {
            "shape": "S64"
          },
          "name": {
            "shape": "Sy"
          },
          "shortDescription": {
            "shape": "Sz"
          },
          "status": {},
          "termRelations": {
            "shape": "S66"
          }
        }
      },
      "idempotent": true
    },
    "UpdateGroupProfile": {
      "http": {
        "method": "PUT",
        "requestUri": "/v2/domains/{domainIdentifier}/group-profiles/{groupIdentifier}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "groupIdentifier",
          "status"
        ],
        "members": {
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "groupIdentifier": {
            "location": "uri",
            "locationName": "groupIdentifier"
          },
          "status": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "domainId": {},
          "groupName": {
            "shape": "S6d"
          },
          "id": {},
          "status": {}
        }
      }
    },
    "UpdateProject": {
      "http": {
        "method": "PATCH",
        "requestUri": "/v2/domains/{domainIdentifier}/projects/{identifier}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "identifier"
        ],
        "members": {
          "description": {
            "shape": "Sq"
          },
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "glossaryTerms": {
            "shape": "S1q"
          },
          "identifier": {
            "location": "uri",
            "locationName": "identifier"
          },
          "name": {
            "shape": "S15"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "createdBy",
          "domainId",
          "id",
          "name"
        ],
        "members": {
          "createdAt": {
            "shape": "S4v"
          },
          "createdBy": {},
          "description": {
            "shape": "Sq"
          },
          "domainId": {},
          "failureReasons": {
            "shape": "S6n"
          },
          "glossaryTerms": {
            "shape": "S1q"
          },
          "id": {},
          "lastUpdatedAt": {
            "shape": "S4v"
          },
          "name": {
            "shape": "S15"
          },
          "projectStatus": {}
        }
      },
      "idempotent": true
    },
    "UpdateSubscriptionGrantStatus": {
      "http": {
        "method": "PATCH",
        "requestUri": "/v2/domains/{domainIdentifier}/subscription-grants/{identifier}/status/{assetIdentifier}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "assetIdentifier",
          "domainIdentifier",
          "identifier",
          "status"
        ],
        "members": {
          "assetIdentifier": {
            "location": "uri",
            "locationName": "assetIdentifier"
          },
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "failureCause": {
            "shape": "S73"
          },
          "identifier": {
            "location": "uri",
            "locationName": "identifier"
          },
          "status": {},
          "targetName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "createdAt",
          "createdBy",
          "domainId",
          "grantedEntity",
          "id",
          "status",
          "subscriptionTargetId",
          "updatedAt"
        ],
        "members": {
          "assets": {
            "shape": "S71"
          },
          "createdAt": {
            "type": "timestamp"
          },
          "createdBy": {},
          "domainId": {},
          "grantedEntity": {
            "shape": "S75"
          },
          "id": {},
          "status": {},
          "subscriptionId": {},
          "subscriptionTargetId": {},
          "updatedAt": {
            "type": "timestamp"
          },
          "updatedBy": {}
        }
      },
      "idempotent": true
    },
    "UpdateSubscriptionRequest": {
      "http": {
        "method": "PATCH",
        "requestUri": "/v2/domains/{domainIdentifier}/subscription-requests/{identifier}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "identifier",
          "requestReason"
        ],
        "members": {
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "identifier": {
            "location": "uri",
            "locationName": "identifier"
          },
          "requestReason": {
            "shape": "Sm"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "createdAt",
          "createdBy",
          "domainId",
          "id",
          "requestReason",
          "status",
          "subscribedListings",
          "subscribedPrincipals",
          "updatedAt"
        ],
        "members": {
          "createdAt": {
            "type": "timestamp"
          },
          "createdBy": {},
          "decisionComment": {
            "shape": "Sh"
          },
          "domainId": {},
          "id": {},
          "requestReason": {
            "shape": "Sm"
          },
          "reviewerId": {},
          "status": {},
          "subscribedListings": {
            "type": "list",
            "member": {
              "shape": "Sp"
            }
          },
          "subscribedPrincipals": {
            "type": "list",
            "member": {
              "shape": "S13"
            }
          },
          "updatedAt": {
            "type": "timestamp"
          },
          "updatedBy": {}
        }
      },
      "idempotent": true
    },
    "UpdateSubscriptionTarget": {
      "http": {
        "method": "PATCH",
        "requestUri": "/v2/domains/{domainIdentifier}/environments/{environmentIdentifier}/subscription-targets/{identifier}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "environmentIdentifier",
          "identifier"
        ],
        "members": {
          "applicableAssetTypes": {
            "shape": "S7j"
          },
          "authorizedPrincipals": {
            "shape": "S7k"
          },
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "environmentIdentifier": {
            "location": "uri",
            "locationName": "environmentIdentifier"
          },
          "identifier": {
            "location": "uri",
            "locationName": "identifier"
          },
          "manageAccessRole": {},
          "name": {
            "shape": "S7m"
          },
          "provider": {},
          "subscriptionTargetConfig": {
            "shape": "S7n"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "applicableAssetTypes",
          "authorizedPrincipals",
          "createdAt",
          "createdBy",
          "domainId",
          "environmentId",
          "id",
          "manageAccessRole",
          "name",
          "projectId",
          "provider",
          "subscriptionTargetConfig",
          "type"
        ],
        "members": {
          "applicableAssetTypes": {
            "shape": "S7j"
          },
          "authorizedPrincipals": {
            "shape": "S7k"
          },
          "createdAt": {
            "type": "timestamp"
          },
          "createdBy": {},
          "domainId": {},
          "environmentId": {},
          "id": {},
          "manageAccessRole": {},
          "name": {
            "shape": "S7m"
          },
          "projectId": {},
          "provider": {},
          "subscriptionTargetConfig": {
            "shape": "S7n"
          },
          "type": {},
          "updatedAt": {
            "type": "timestamp"
          },
          "updatedBy": {}
        }
      },
      "idempotent": true
    },
    "UpdateUserProfile": {
      "http": {
        "method": "PUT",
        "requestUri": "/v2/domains/{domainIdentifier}/user-profiles/{userIdentifier}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "domainIdentifier",
          "status",
          "userIdentifier"
        ],
        "members": {
          "domainIdentifier": {
            "location": "uri",
            "locationName": "domainIdentifier"
          },
          "status": {},
          "type": {},
          "userIdentifier": {
            "location": "uri",
            "locationName": "userIdentifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "details": {
            "shape": "S7u"
          },
          "domainId": {},
          "id": {},
          "status": {},
          "type": {}
        }
      }
    }
  },
  "shapes": {
    "Sh": {
      "type": "string",
      "sensitive": true
    },
    "Sm": {
      "type": "string",
      "sensitive": true
    },
    "Sp": {
      "type": "structure",
      "required": [
        "description",
        "id",
        "item",
        "name",
        "ownerProjectId"
      ],
      "members": {
        "description": {
          "shape": "Sq"
        },
        "id": {},
        "item": {
          "type": "structure",
          "members": {
            "assetListing": {
              "type": "structure",
              "members": {
                "entityId": {},
                "entityRevision": {},
                "entityType": {},
                "forms": {},
                "glossaryTerms": {
                  "shape": "Sw"
                }
              }
            }
          },
          "union": true
        },
        "name": {},
        "ownerProjectId": {},
        "ownerProjectName": {},
        "revision": {}
      }
    },
    "Sq": {
      "type": "string",
      "sensitive": true
    },
    "Sw": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "name": {
            "shape": "Sy"
          },
          "shortDescription": {
            "shape": "Sz"
          }
        }
      }
    },
    "Sy": {
      "type": "string",
      "sensitive": true
    },
    "Sz": {
      "type": "string",
      "sensitive": true
    },
    "S13": {
      "type": "structure",
      "members": {
        "project": {
          "type": "structure",
          "members": {
            "id": {},
            "name": {
              "shape": "S15"
            }
          }
        }
      },
      "union": true
    },
    "S15": {
      "type": "string",
      "sensitive": true
    },
    "S1k": {
      "type": "string",
      "sensitive": true
    },
    "S1l": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "formName"
        ],
        "members": {
          "content": {},
          "formName": {},
          "typeIdentifier": {},
          "typeRevision": {}
        },
        "sensitive": true
      },
      "sensitive": true
    },
    "S1q": {
      "type": "list",
      "member": {}
    },
    "S1s": {
      "type": "string",
      "sensitive": true
    },
    "S1t": {
      "type": "structure",
      "members": {
        "businessNameGeneration": {
          "type": "structure",
          "members": {
            "enabled": {
              "type": "boolean"
            }
          }
        }
      }
    },
    "S1x": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "formName"
        ],
        "members": {
          "content": {},
          "formName": {},
          "typeName": {
            "shape": "S1z"
          },
          "typeRevision": {}
        }
      }
    },
    "S1z": {
      "type": "string",
      "sensitive": true
    },
    "S20": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "formName",
          "timestamp",
          "typeIdentifier"
        ],
        "members": {
          "contentSummary": {},
          "formName": {},
          "id": {},
          "timestamp": {
            "type": "timestamp"
          },
          "typeIdentifier": {},
          "typeRevision": {}
        }
      }
    },
    "S26": {
      "type": "structure",
      "required": [
        "listingId",
        "listingStatus"
      ],
      "members": {
        "listingId": {},
        "listingStatus": {}
      }
    },
    "S29": {
      "type": "structure",
      "members": {
        "columnConfiguration": {
          "type": "structure",
          "members": {
            "includedColumnNames": {
              "shape": "S2b"
            }
          }
        },
        "rowConfiguration": {
          "type": "structure",
          "required": [
            "rowFilter"
          ],
          "members": {
            "rowFilter": {
              "shape": "S2d"
            },
            "sensitive": {
              "type": "boolean"
            }
          }
        }
      },
      "union": true
    },
    "S2b": {
      "type": "list",
      "member": {}
    },
    "S2d": {
      "type": "structure",
      "members": {
        "and": {
          "shape": "S2e"
        },
        "expression": {
          "type": "structure",
          "members": {
            "equalTo": {
              "type": "structure",
              "required": [
                "columnName",
                "value"
              ],
              "members": {
                "columnName": {},
                "value": {}
              }
            },
            "greaterThan": {
              "type": "structure",
              "required": [
                "columnName",
                "value"
              ],
              "members": {
                "columnName": {},
                "value": {}
              }
            },
            "greaterThanOrEqualTo": {
              "type": "structure",
              "required": [
                "columnName",
                "value"
              ],
              "members": {
                "columnName": {},
                "value": {}
              }
            },
            "in": {
              "type": "structure",
              "required": [
                "columnName",
                "values"
              ],
              "members": {
                "columnName": {},
                "values": {
                  "shape": "S2k"
                }
              }
            },
            "isNotNull": {
              "type": "structure",
              "required": [
                "columnName"
              ],
              "members": {
                "columnName": {}
              }
            },
            "isNull": {
              "type": "structure",
              "required": [
                "columnName"
              ],
              "members": {
                "columnName": {}
              }
            },
            "lessThan": {
              "type": "structure",
              "required": [
                "columnName",
                "value"
              ],
              "members": {
                "columnName": {},
                "value": {}
              }
            },
            "lessThanOrEqualTo": {
              "type": "structure",
              "required": [
                "columnName",
                "value"
              ],
              "members": {
                "columnName": {},
                "value": {}
              }
            },
            "like": {
              "type": "structure",
              "required": [
                "columnName",
                "value"
              ],
              "members": {
                "columnName": {},
                "value": {}
              }
            },
            "notEqualTo": {
              "type": "structure",
              "required": [
                "columnName",
                "value"
              ],
              "members": {
                "columnName": {},
                "value": {}
              }
            },
            "notIn": {
              "type": "structure",
              "required": [
                "columnName",
                "values"
              ],
              "members": {
                "columnName": {},
                "values": {
                  "shape": "S2k"
                }
              }
            },
            "notLike": {
              "type": "structure",
              "required": [
                "columnName",
                "value"
              ],
              "members": {
                "columnName": {},
                "value": {}
              }
            }
          },
          "union": true
        },
        "or": {
          "shape": "S2e"
        }
      },
      "union": true
    },
    "S2e": {
      "type": "list",
      "member": {
        "shape": "S2d"
      }
    },
    "S2k": {
      "type": "list",
      "member": {}
    },
    "S2t": {
      "type": "string",
      "sensitive": true
    },
    "S33": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "required": [
          "typeName",
          "typeRevision"
        ],
        "members": {
          "required": {
            "type": "boolean"
          },
          "typeName": {
            "shape": "S1z"
          },
          "typeRevision": {}
        }
      }
    },
    "S36": {
      "type": "structure",
      "members": {
        "glueRunConfiguration": {
          "type": "structure",
          "required": [
            "relationalFilterConfigurations"
          ],
          "members": {
            "autoImportDataQualityResult": {
              "type": "boolean"
            },
            "dataAccessRole": {},
            "relationalFilterConfigurations": {
              "shape": "S39"
            }
          }
        },
        "redshiftRunConfiguration": {
          "type": "structure",
          "required": [
            "redshiftCredentialConfiguration",
            "redshiftStorage",
            "relationalFilterConfigurations"
          ],
          "members": {
            "dataAccessRole": {},
            "redshiftCredentialConfiguration": {
              "shape": "S3j"
            },
            "redshiftStorage": {
              "shape": "S3l"
            },
            "relationalFilterConfigurations": {
              "shape": "S39"
            }
          }
        }
      },
      "union": true
    },
    "S39": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "databaseName"
        ],
        "members": {
          "databaseName": {},
          "filterExpressions": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "expression",
                "type"
              ],
              "members": {
                "expression": {},
                "type": {}
              }
            }
          },
          "schemaName": {}
        }
      }
    },
    "S3j": {
      "type": "structure",
      "required": [
        "secretManagerArn"
      ],
      "members": {
        "secretManagerArn": {}
      }
    },
    "S3l": {
      "type": "structure",
      "members": {
        "redshiftClusterSource": {
          "type": "structure",
          "required": [
            "clusterName"
          ],
          "members": {
            "clusterName": {}
          }
        },
        "redshiftServerlessSource": {
          "type": "structure",
          "required": [
            "workgroupName"
          ],
          "members": {
            "workgroupName": {}
          }
        }
      },
      "union": true
    },
    "S3r": {
      "type": "string",
      "sensitive": true
    },
    "S3s": {
      "type": "structure",
      "members": {
        "enableBusinessNameGeneration": {
          "type": "boolean"
        }
      }
    },
    "S3t": {
      "type": "structure",
      "members": {
        "schedule": {},
        "timezone": {}
      },
      "sensitive": true
    },
    "S3y": {
      "type": "structure",
      "members": {
        "glueRunConfiguration": {
          "type": "structure",
          "required": [
            "relationalFilterConfigurations"
          ],
          "members": {
            "accountId": {},
            "autoImportDataQualityResult": {
              "type": "boolean"
            },
            "dataAccessRole": {},
            "region": {},
            "relationalFilterConfigurations": {
              "shape": "S39"
            }
          }
        },
        "redshiftRunConfiguration": {
          "type": "structure",
          "required": [
            "redshiftCredentialConfiguration",
            "redshiftStorage",
            "relationalFilterConfigurations"
          ],
          "members": {
            "accountId": {},
            "dataAccessRole": {},
            "redshiftCredentialConfiguration": {
              "shape": "S3j"
            },
            "redshiftStorage": {
              "shape": "S3l"
            },
            "region": {},
            "relationalFilterConfigurations": {
              "shape": "S39"
            }
          }
        }
      },
      "union": true
    },
    "S47": {
      "type": "timestamp",
      "timestampFormat": "iso8601"
    },
    "S48": {
      "type": "structure",
      "required": [
        "errorType"
      ],
      "members": {
        "errorDetail": {},
        "errorType": {}
      }
    },
    "S4g": {
      "type": "structure",
      "members": {
        "type": {},
        "userAssignment": {}
      }
    },
    "S4j": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S4q": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "name": {},
          "value": {}
        }
      }
    },
    "S4v": {
      "type": "timestamp",
      "timestampFormat": "iso8601"
    },
    "S4w": {
      "type": "structure",
      "members": {
        "endTimeoutMinutes": {
          "type": "integer"
        },
        "startTimeoutMinutes": {
          "type": "integer"
        }
      }
    },
    "S4z": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "parameters",
          "type"
        ],
        "members": {
          "auth": {},
          "parameters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "key": {},
                "value": {}
              }
            }
          },
          "type": {}
        }
      }
    },
    "S55": {
      "type": "structure",
      "members": {
        "deploymentId": {},
        "deploymentStatus": {},
        "deploymentType": {},
        "failureReason": {
          "type": "structure",
          "required": [
            "message"
          ],
          "members": {
            "code": {},
            "message": {}
          }
        },
        "isDeploymentComplete": {
          "type": "boolean"
        },
        "messages": {
          "type": "list",
          "member": {}
        }
      }
    },
    "S5b": {
      "type": "string",
      "sensitive": true
    },
    "S5c": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "type",
          "value"
        ],
        "members": {
          "name": {},
          "provider": {},
          "type": {},
          "value": {}
        }
      }
    },
    "S5e": {
      "type": "structure",
      "members": {
        "cloudFormation": {
          "type": "structure",
          "required": [
            "templateUrl"
          ],
          "members": {
            "templateUrl": {}
          }
        }
      },
      "union": true
    },
    "S5h": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "fieldType",
          "keyName"
        ],
        "members": {
          "defaultValue": {},
          "description": {
            "shape": "Sq"
          },
          "fieldType": {},
          "isEditable": {
            "type": "boolean"
          },
          "isOptional": {
            "type": "boolean"
          },
          "keyName": {}
        }
      }
    },
    "S5l": {
      "type": "structure",
      "members": {
        "awsConsoleLink": {
          "type": "structure",
          "members": {
            "uri": {}
          }
        }
      },
      "union": true
    },
    "S5q": {
      "type": "string",
      "sensitive": true
    },
    "S5t": {
      "type": "structure",
      "members": {
        "smithy": {}
      },
      "sensitive": true,
      "union": true
    },
    "S5y": {
      "type": "string",
      "sensitive": true
    },
    "S5z": {
      "type": "string",
      "sensitive": true
    },
    "S64": {
      "type": "string",
      "sensitive": true
    },
    "S66": {
      "type": "structure",
      "members": {
        "classifies": {
          "type": "list",
          "member": {}
        },
        "isA": {
          "type": "list",
          "member": {}
        }
      }
    },
    "S6d": {
      "type": "string",
      "sensitive": true
    },
    "S6n": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "code": {},
          "message": {}
        }
      }
    },
    "S6s": {
      "type": "structure",
      "members": {
        "groupIdentifier": {},
        "userIdentifier": {}
      },
      "union": true
    },
    "S71": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "assetId",
          "assetRevision",
          "status"
        ],
        "members": {
          "assetId": {},
          "assetRevision": {},
          "failureCause": {
            "shape": "S73"
          },
          "failureTimestamp": {
            "type": "timestamp"
          },
          "grantedTimestamp": {
            "type": "timestamp"
          },
          "status": {},
          "targetName": {}
        }
      }
    },
    "S73": {
      "type": "structure",
      "members": {
        "message": {}
      }
    },
    "S75": {
      "type": "structure",
      "members": {
        "listing": {
          "type": "structure",
          "required": [
            "id",
            "revision"
          ],
          "members": {
            "id": {},
            "revision": {}
          }
        }
      },
      "union": true
    },
    "S7j": {
      "type": "list",
      "member": {}
    },
    "S7k": {
      "type": "list",
      "member": {}
    },
    "S7m": {
      "type": "string",
      "sensitive": true
    },
    "S7n": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "content",
          "formName"
        ],
        "members": {
          "content": {},
          "formName": {}
        }
      }
    },
    "S7u": {
      "type": "structure",
      "members": {
        "iam": {
          "type": "structure",
          "members": {
            "arn": {}
          }
        },
        "sso": {
          "type": "structure",
          "members": {
            "firstName": {
              "type": "string",
              "sensitive": true
            },
            "lastName": {
              "type": "string",
              "sensitive": true
            },
            "username": {
              "type": "string",
              "sensitive": true
            }
          }
        }
      },
      "union": true
    },
    "S8a": {
      "type": "structure",
      "members": {
        "glueSelfGrantStatus": {
          "type": "structure",
          "required": [
            "selfGrantStatusDetails"
          ],
          "members": {
            "selfGrantStatusDetails": {
              "shape": "S8c"
            }
          }
        },
        "redshiftSelfGrantStatus": {
          "type": "structure",
          "required": [
            "selfGrantStatusDetails"
          ],
          "members": {
            "selfGrantStatusDetails": {
              "shape": "S8c"
            }
          }
        }
      },
      "union": true
    },
    "S8c": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "databaseName",
          "status"
        ],
        "members": {
          "databaseName": {},
          "failureCause": {},
          "schemaName": {},
          "status": {}
        }
      }
    },
    "S9l": {
      "type": "structure",
      "members": {
        "added": {
          "type": "integer"
        },
        "failed": {
          "type": "integer"
        },
        "skipped": {
          "type": "integer"
        },
        "unchanged": {
          "type": "integer"
        },
        "updated": {
          "type": "integer"
        }
      }
    },
    "S9y": {
      "type": "list",
      "member": {}
    },
    "Sa0": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "lakeFormationConfiguration": {
            "type": "structure",
            "members": {
              "locationRegistrationExcludeS3Locations": {
                "type": "list",
                "member": {}
              },
              "locationRegistrationRole": {}
            }
          }
        },
        "union": true
      }
    },
    "Sa5": {
      "type": "map",
      "key": {},
      "value": {
        "type": "map",
        "key": {},
        "value": {}
      }
    },
    "Sad": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "name",
          "revision"
        ],
        "members": {
          "name": {
            "shape": "S1z"
          },
          "revision": {}
        }
      }
    },
    "Saq": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "eventTimestamp": {
            "type": "timestamp"
          },
          "id": {}
        }
      }
    },
    "Sb0": {
      "type": "structure",
      "required": [
        "identifier",
        "type"
      ],
      "members": {
        "identifier": {},
        "revision": {},
        "type": {}
      }
    },
    "Sbj": {
      "type": "structure",
      "required": [
        "formName",
        "timestamp",
        "typeIdentifier"
      ],
      "members": {
        "content": {},
        "formName": {},
        "id": {},
        "timestamp": {
          "type": "timestamp"
        },
        "typeIdentifier": {},
        "typeRevision": {}
      }
    },
    "Sff": {
      "type": "list",
      "member": {}
    },
    "Sfh": {
      "type": "structure",
      "members": {
        "and": {
          "shape": "Sfi"
        },
        "filter": {
          "type": "structure",
          "required": [
            "attribute",
            "value"
          ],
          "members": {
            "attribute": {},
            "value": {}
          }
        },
        "or": {
          "shape": "Sfi"
        }
      },
      "union": true
    },
    "Sfi": {
      "type": "list",
      "member": {
        "shape": "Sfh"
      }
    },
    "Sfm": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "attribute"
        ],
        "members": {
          "attribute": {}
        }
      }
    },
    "Sfq": {
      "type": "structure",
      "required": [
        "attribute"
      ],
      "members": {
        "attribute": {},
        "order": {}
      }
    }
  }
}