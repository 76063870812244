{
  "pagination": {
    "ListPermissions": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "permissions"
    },
    "ListVersions": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "grafanaVersions"
    },
    "ListWorkspaceServiceAccountTokens": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "serviceAccountTokens"
    },
    "ListWorkspaceServiceAccounts": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "serviceAccounts"
    },
    "ListWorkspaces": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "workspaces"
    }
  }
}
