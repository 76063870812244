{
  "pagination": {
    "ListAgentActionGroups": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "actionGroupSummaries"
    },
    "ListAgentAliases": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "agentAliasSummaries"
    },
    "ListAgentKnowledgeBases": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "agentKnowledgeBaseSummaries"
    },
    "ListAgentVersions": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "agentVersionSummaries"
    },
    "ListAgents": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "agentSummaries"
    },
    "ListDataSources": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "dataSourceSummaries"
    },
    "ListFlowAliases": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "flowAliasSummaries"
    },
    "ListFlowVersions": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "flowVersionSummaries"
    },
    "ListFlows": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "flowSummaries"
    },
    "ListIngestionJobs": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "ingestionJobSummaries"
    },
    "ListKnowledgeBases": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "knowledgeBaseSummaries"
    },
    "ListPrompts": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "promptSummaries"
    }
  }
}
