{
  "pagination": {
    "ListApplications": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "applications"
    },
    "ListJobRunAttempts": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "jobRunAttempts"
    },
    "ListJobRuns": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "jobRuns"
    }
  }
}
