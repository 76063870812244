{
  "pagination": {
    "ListAnalysisTemplates": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "analysisTemplateSummaries"
    },
    "ListCollaborationAnalysisTemplates": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "collaborationAnalysisTemplateSummaries"
    },
    "ListCollaborationConfiguredAudienceModelAssociations": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "collaborationConfiguredAudienceModelAssociationSummaries"
    },
    "ListCollaborationIdNamespaceAssociations": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "collaborationIdNamespaceAssociationSummaries"
    },
    "ListCollaborationPrivacyBudgetTemplates": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "collaborationPrivacyBudgetTemplateSummaries"
    },
    "ListCollaborationPrivacyBudgets": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "collaborationPrivacyBudgetSummaries"
    },
    "ListCollaborations": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "collaborationList"
    },
    "ListConfiguredAudienceModelAssociations": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "configuredAudienceModelAssociationSummaries"
    },
    "ListConfiguredTableAssociations": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "configuredTableAssociationSummaries"
    },
    "ListConfiguredTables": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "configuredTableSummaries"
    },
    "ListIdMappingTables": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "idMappingTableSummaries"
    },
    "ListIdNamespaceAssociations": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "idNamespaceAssociationSummaries"
    },
    "ListMembers": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "memberSummaries"
    },
    "ListMemberships": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "membershipSummaries"
    },
    "ListPrivacyBudgetTemplates": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "privacyBudgetTemplateSummaries"
    },
    "ListPrivacyBudgets": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "privacyBudgetSummaries"
    },
    "ListProtectedQueries": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "protectedQueries"
    },
    "ListSchemas": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "schemaSummaries"
    }
  }
}
