import "./App.css";
import React from "react";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import { BrowserRouter, useNavigate ,Routes, Route, link, Navigate } from 'react-router-dom';
import Jepx_price from "./components/jepx_price";


function App() {
  return (
    <BrowserRouter>
            {/* <Routes> */}
            <Route path="/:path(|afterfit-co-jp-2234)">
                <Jepx_price />
              </Route>
        </BrowserRouter>
  );
}

export default App;

