{
  "pagination": {
    "ListConfigurationManagers": {
      "input_token": "StartingToken",
      "output_token": "NextToken",
      "limit_key": "MaxItems",
      "result_key": "ConfigurationManagersList"
    }
  }
}
