import React from "react";
import {
  AreaChart,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Line,
  LineChart,
  ComposedChart
} from "recharts";
import React, { useEffect,useState,useRef,useCallback } from 'react';
import "./Jepx.css";
import { Text } from '@mantine/core';

const data_time = [
  "00:00",
  "00:30",
  "01:00",
  "01:30",
  "02:00",
  "02:30",
  "03:00",
  "03:30",
  "04:00",
  "04:30",
  "05:00",
  "05:30",
  "06:00",
  "06:30",
  "07:00",
  "07:30",
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
  "23:00",
  "23:30"
]


let setsuyaku

export default function Jepx_chart(props) {

  if(props.use_data!=null){
    // console.log("use_data_jepx",props.use_data)

    let data_chart = []
    let value = props.name

    for(let i=0; i<data_time.length; i++){
      data_chart.push({
        label:data_time[i],
        value:props.use_data[i]
      })
    }

    // console.log("props.use_data",props.use_data)




  return (
  <div className="jepxchart" id="jepxchart" >
        <div className="header_chart">
          <div>
            <Text fz={30} fw={600} style={{ textAlign: "center" }}>{props.name}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color: 'red' }}>最高値:{(Math.max(...props.use_data)).toFixed(2)}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color: '#2a7fc6' }}>最安値:{(Math.min(...props.use_data)).toFixed(2)}</span></Text>
          </div>
        </div>
        <div className="LineChart">
        <LineChart
          width={600}
          height={300}
          data={data_chart}
          margin={{
            top: 5,
            right: -10,
            left: -2,
            bottom: 5
          }}
        >
          <CartesianGrid strokeDasharray="1 1" />
          <XAxis dataKey="label" />
          <YAxis allowDecimals={false} label={{ value: "(円/kWh)", dx: -10, angle: -90,}} />
          {/* <Tooltip separator=" : "/> */}
          <Legend />
          <Line  strokeWidth={5} dot={false} name={props.name} dataKey="value" unit=" 円/kWh" stroke={props.color} />
        </LineChart>
        </div>
  </div>
    
  );
  }
  else{
  // let data_dummy =[]
  // console.log(data_js['北海道の価格']['data'])

  // let kotak = [data_js['北海道の価格']['data']]
  // console.log(kotak)

  // kotak.map((row,index) => (
  //   console.log("row",row)
  // ))

  // for(let prop in kotak){
  //   console.log(kotak[prop])
  // }



  // let label = Object.getOwnPropertyNames(dummy["result"]['月間時別の発電量'])
  // let label = Object.getOwnPropertyNames(data_js['北海道の価格']['data'])
  // console.log(Object.getOwnPropertyNames(data_js))
  // let total = 0
  // for(let x of label){
  //   data_dummy.push({
  //       label:x,
  //       北海道の価格:data_js['北海道の価格']['data'][x]
  //   })
  // }
  // console.log(label)
  

  // console.log("data_dummy",data_dummy)

    return(
    //   <div className="Rechart" id="Rechart" >
    // <h3 style={{ textAlign: "center" }}>{Object.getOwnPropertyNames(data_js)}</h3>
    // <h3 style={{ textAlign: "center" }}>最高値:{data_js['北海道の価格']['max_data']}</h3>
    // <h3 style={{ textAlign: "center" }}>最安値:{data_js['北海道の価格']['min_data']}</h3>
    // <LineChart
    //   width={600}
    //   height={400}
    //   data={data_dummy}
    //   margin={{
    //     top: 5,
    //     right: -10,
    //     left: -2,
    //     bottom: 5
    //   }}
    // >
    //   <CartesianGrid strokeDasharray="1 1" />
    //   <XAxis dataKey="label" />
    //   <YAxis allowDecimals={false} label={{ value: "(円/kWh)", dx: -10, angle: -90,}} />
    //   <Tooltip separator=" : "/>
    //   <Legend />
    //   <Line  dataKey="北海道の価格" unit=" 円/kWh" fill="#f6b262" />
    //   {/* <Area  dataKey="しろくまカーポートの発電量" unit="kw0.5h" fill="orange" /> */}
    //   {/* <Line
    //       dot={true}
    //       strokeWidth={2}
    //       strokeLinecap="round"
    //       type="monotone"
    //       dataKey="最適な発電量"
    //       stroke="#00C301"
    //       unit="kw"
    //     /> */}
    // </LineChart>
    // </div>
    <div>
      
    </div>
      
    )
    }
}