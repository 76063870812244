{
  "pagination": {
    "GetAgentMemory": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxItems",
      "result_key": "memoryContents"
    },
    "Retrieve": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "retrievalResults"
    }
  }
}
