{
  "pagination": {
    "ListLinuxSubscriptionInstances": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "Instances"
    },
    "ListLinuxSubscriptions": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "Subscriptions"
    },
    "ListRegisteredSubscriptionProviders": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "RegisteredSubscriptionProviders"
    }
  }
}
