{
  "pagination": {
    "ListTestCases": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "testCases"
    },
    "ListTestConfigurations": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "testConfigurations"
    },
    "ListTestRunSteps": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "testRunSteps"
    },
    "ListTestRunTestCases": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "testRunTestCases"
    },
    "ListTestRuns": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "testRuns"
    },
    "ListTestSuites": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "testSuites"
    }
  }
}
