{
  "pagination": {
    "ListChallengeMetadata": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "Challenges"
    },
    "ListConnectors": {
      "input_token": "NextToken",
      "output_token": "NextToken",
      "limit_key": "MaxResults",
      "result_key": "Connectors"
    }
  }
}
