{
  "pagination": {
    "ListApiKeys": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "apiKeys"
    },
    "ListDataSources": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "dataSources"
    },
    "ListDomainNames": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "domainNameConfigs"
    },
    "ListFunctions": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "functions"
    },
    "ListGraphqlApis": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "graphqlApis"
    },
    "ListResolvers": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "resolvers"
    },
    "ListResolversByFunction": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "resolvers"
    },
    "ListSourceApiAssociations": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "sourceApiAssociationSummaries"
    },
    "ListTypes": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "types"
    },
    "ListTypesByAssociation": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "types"
    }
  }
}
